import { useEffect, useRef, useState } from 'react';
import { StoreName, getIndexedDB } from './indexedDB';

export const useIndexedDBStorage = <D>(
  key: StoreName,
  initialValue?: D | (() => D),
): [D | undefined, (value: D) => void, boolean] => {
  const [storedValue, setStoredValue] = useState<D | undefined>(initialValue);
  const { read, write } = useRef(getIndexedDB(key)).current;
  const [loading, setLoading] = useState(true);

  // Fetch the initial value from IndexedDB
  useEffect(() => {
    setLoading(true);
    read<D>().then((storedFilters) => {
      setStoredValue(storedFilters);
      setLoading(false);
    });
  }, [read, setLoading, setStoredValue]);

  useEffect(() => {
    write(storedValue);
  }, [storedValue]);

  return [storedValue, setStoredValue, loading];
};

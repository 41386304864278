import React, { useMemo } from 'react';
import { defaultTheme } from './utils/themes';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { AuthenticationProvider, AuthenticationProviderProps } from '@sede-x/auth';
import Routes from './Routes';

const env = process.env;

type AuthConfigProps = {
  issuer: AuthenticationProviderProps['issuer'];
  clientId: AuthenticationProviderProps['clientId'];
  redirectUrl: AuthenticationProviderProps['redirectUrl'];
  extras: AuthenticationProviderProps['extras'];
};

let authConfigurationProps: AuthConfigProps | null = null;

if (env) {
  authConfigurationProps = {
    issuer: env.REACT_APP_ISSUER,
    clientId: env.REACT_APP_CLIENTID,
    redirectUrl: `${window.location.origin}/auth/callback`,
    extras: {},
  };
}

const hasEnvironmentVariables =
  authConfigurationProps &&
  Object.keys(authConfigurationProps).every(
    (env) => authConfigurationProps[env as keyof AuthConfigProps] !== undefined,
  );

if (!hasEnvironmentVariables) {
  throw new Error('Auth environment variables need to be defined');
}

export function Root() {
  const theme = useMemo(() => defaultTheme(), []);

  return (
    <AuthenticationProvider {...authConfigurationProps} scopes={['openid', 'profile', 'email']}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes />
        </ThemeProvider>
      </BrowserRouter>
    </AuthenticationProvider>
  );
}

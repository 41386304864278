import { useEffect } from 'react';

const Login = ({ login }: { login: () => Promise<void> }): null => {
  useEffect(() => {
    login?.();
  }, []);

  return null;
};

export default Login;

/*
 * © 2021 Thoughtworks, Inc.
 */

import React, { FunctionComponent } from 'react';
import FilterDropdown from '../../../../../common/FilterDropdown/FilterDropdown';
import { DropdownFilterOptions, DropdownOption, FilterProps } from '../../../../../types';
import { getCurrentFilterLabel, getFilterDisplayValue } from '../../../../../common/FilterBar/utils/FiltersUtil';

const ServiceFilter: FunctionComponent<FilterProps> = ({ filters, setFilters, options }) => {
  return (
    <FilterDropdown
      id="services-filter"
      popperWidth={300}
      allLabel={getCurrentFilterLabel(DropdownFilterOptions.SERVICES)}
      displayValue={getFilterDisplayValue(DropdownFilterOptions.SERVICES, filters, options)}
      options={options?.services}
      selections={filters?.services}
      updateSelections={(selections: DropdownOption[]) =>
        setFilters({
          ...filters,
          services: selections,
        })
      }
    />
  );
};

export default ServiceFilter;

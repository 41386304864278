/*
 * © 2021 Thoughtworks, Inc.
 */

import React, { FunctionComponent, ReactElement } from 'react';
import { Container, Divider, Grid } from '@material-ui/core';
import SidePanel from '../../../common/SidePanel';
import { RecommendationRow } from '../../../types';
import { RecommendationsPanelRow, RecommendationsPanelColumn } from './layout';
import useStyles from './recommendationsSidePanelStyles';
import { tableFormatNearZero, tableFormatRawCo2e } from '../../../utils/helpers/transformData';
import { co2eUnitLabel } from '../../../utils/helpers';
import { currency } from '../../../utils/helpers/units';
import { getResourceNameFromRecommendation } from '../RecommendationsTable/RecommendationsTable';

export type RecommendationsSidePanelProps = {
  recommendation: RecommendationRow;
  onClose?: () => void;
};

const RecommendationsSidePanel: FunctionComponent<RecommendationsSidePanelProps> = ({
  recommendation,
  onClose,
}): ReactElement => {
  const classes = useStyles();
  const resourceName = getResourceNameFromRecommendation(recommendation);

  return (
    <SidePanel
      drawerWidth={475}
      title="Recommendation Details"
      defaultIsOpen
      openOnChange={recommendation}
      onClose={onClose}
    >
      <Container className={classes.detailsContainer}>
        <RecommendationsPanelRow label="Cloud Provider" content={recommendation.cloudProvider} />
        <RecommendationsPanelRow label="Subscription Name" content={recommendation.accountName} />
        <RecommendationsPanelRow label="Account ID" content={recommendation.accountId} />
        <RecommendationsPanelRow label="Region" content={recommendation.region} />
        <RecommendationsPanelRow label="Resource Name" content={resourceName} />
        <RecommendationsPanelRow label="Resource ID" content={recommendation.resourceId} />
      </Container>
      <Divider />
      <Container className={classes.detailsContainer}>
        <RecommendationsPanelColumn label="Recommendation Type" content={recommendation.recommendationType} />
        <RecommendationsPanelColumn
          label="Recommendation Detail"
          content={recommendation.recommendationDetail}
          hasLeftAlignedContent
        />
      </Container>
      <Grid className={classes.savingsContainer} container wrap="nowrap">
        <RecommendationsPanelColumn
          label="Cost Savings"
          subLabel={`(${currency.abbreviation.toUpperCase()})`}
          content={tableFormatNearZero(recommendation.costSavings ?? 0)}
        />
        <RecommendationsPanelColumn
          label="CO2e Savings"
          subLabel={`(${co2eUnitLabel[recommendation.co2eUnit]})`}
          content={tableFormatRawCo2e(recommendation.co2eUnit, recommendation.co2eSavings)}
        />
        <RecommendationsPanelColumn
          label="Energy Savings"
          subLabel="(kilowatt hours)"
          content={tableFormatNearZero(recommendation.kilowattHourSavings)}
        />
      </Grid>
    </SidePanel>
  );
};

export default RecommendationsSidePanel;

import { TableCell, TableHead, TableRow, TableSortLabel, Checkbox } from '@mui/material';
import { EnhancedTableProps, HeadCell } from 'src/types';

export const headCells: readonly HeadCell[] = [
  {
    id: 'subscriptionName',
    label: 'Subscription Name',
    width: '300px',
  },
  {
    id: 'resourceGroupId',
    label: 'Resource Group',
    width: '300px',
  },
  {
    id: 'resourceName',
    label: 'Resource',
    width: '250px',
  },
  {
    id: 'regionName',
    label: 'Region',
    width: '150px',
  },
  {
    id: 'serviceName',
    label: 'Service',
    width: '200px',
  },
  {
    id: 'currentEmissions',
    label: 'Last Week Emissions (kgCO2e)',
    width: '150px',
  },
  {
    id: 'previousEmissions',
    label: 'Fortnight Week Emissions (kgCO2e)',
    width: '150px',
  },
  {
    id: 'change',
    label: 'Change in Emissions (kgCO2e)',
    width: '150px',
  },
  {
    id: 'changePercentage',
    label: 'Change in Emissions (%)',
    width: '150px',
  },
];

const EnhancedTableHead = ({
  selectAllResources,
  sortBy,
  sortDir,
  handleSortBy,
  handleSortDir,
  handleSelectAllClick,
}: EnhancedTableProps) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox checked={selectAllResources || false} onChange={handleSelectAllClick} />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            sortDirection={sortBy === headCell.id ? sortDir : false}
            sx={{
              width: headCell.width,
              minWidth: headCell.width,
            }}
          >
            <TableSortLabel
              active={sortBy === headCell.id}
              direction={sortBy === headCell.id ? sortDir : 'asc'}
              onClick={() => {
                handleSortBy(headCell.id);
                handleSortDir(sortDir === 'asc' ? 'desc' : 'asc');
              }}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;

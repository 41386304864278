/*
 * © 2021 Thoughtworks, Inc.
 */

import React, { FunctionComponent } from 'react';
import FilterDropdown from '../../../../../common/FilterDropdown';
import { DropdownFilterOptions, FilterProps, FilterSelectionsMaxCount } from '../../../../../types';
import { getCurrentFilterLabel, getFilterDisplayValue } from '../../../../../common/FilterBar/utils/FiltersUtil';

type Props = Omit<FilterProps, 'setFilters'> & {
  updateMaxSelectionsCountWarning: NonNullable<FilterProps['updateMaxSelectionsCountWarning']>;
  updateSelections: NonNullable<FilterProps['updateSelections']>;
};

const AccountFilter: FunctionComponent<Props> = ({
  filters,
  options,
  updateMaxSelectionsCountWarning,
  updateSelections,
}) => {
  return (
    <FilterDropdown
      id="accounts-filter"
      popperWidth={550}
      allLabel={getCurrentFilterLabel(DropdownFilterOptions.ACCOUNTS)}
      displayValue={getFilterDisplayValue(DropdownFilterOptions.ACCOUNTS, filters, options)}
      options={options?.accounts}
      selections={filters?.accounts}
      updateSelections={updateSelections}
      maxSelectionsCount={FilterSelectionsMaxCount.Subscriptions}
      updateMaxSelectionsCountWarning={({ show, label }) => {
        updateMaxSelectionsCountWarning({ show, label });
      }}
    />
  );
};

export default AccountFilter;

import { useAuthentication as useSedeXAuth } from '@sede-x/auth';

import appConfig from '../../Config';

export const useAuthentication = appConfig.REACT_APP_DISABLE_AUTH
  ? () => ({
      isLoggedIn: true,
      authorizeResult: {
        accessToken: 'dev-token',
        tokenType: 'Bearer',
        refreshToken: 'dev-refresh-token',
      },
      login: () => Promise.resolve(),
      isLoading: false,
      hasError: false,
    })
  : useSedeXAuth;

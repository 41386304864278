import { useState, useEffect, useCallback, useRef, DependencyList } from 'react';
import axios, { AxiosError, AxiosResponse } from 'axios';

export const useAxiosRequest = <T>(
  getResponse: (signal: AbortSignal) => Promise<AxiosResponse<T>>,
  dependencies: DependencyList,
) => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError | null>(null);
  const abortControllerRef = useRef(new AbortController());

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);

    // Abort the previous request
    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();

    try {
      const response = await getResponse(abortControllerRef.current.signal);
      setData(response.data);
      setLoading(false);
    } catch (err) {
      if (!axios.isCancel(err)) {
        setError(err as AxiosError);
        setLoading(false);
      }
    }
  }, [...dependencies]);

  useEffect(() => {
    fetchData();

    return () => abortControllerRef.current.abort();
  }, [fetchData]);

  return { data, loading, error };
};

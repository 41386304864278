import { FilterModel } from '@sede-x/ccf-common';

import { AggregatedTimeSeriesDateValues, ServiceResult } from '../../types';
import { useAxiosRequest } from './useAxiosRequest';
import { useAuthentication } from './useAuthentication';
import { getServerAxiosInstance } from '../axios';
import { resourceNameMapperCallback } from '../../pages/EmissionsMetricsPage/utils';

export const useAggregatedDailyEmissionsTimeSeries = (
  filters: FilterModel,
): ServiceResult<AggregatedTimeSeriesDateValues | null> => {
  const { authorizeResult } = useAuthentication();
  const accessToken = authorizeResult?.accessToken;

  const { data, loading, error } = useAxiosRequest<AggregatedTimeSeriesDateValues>(
    async (signal) => {
      const res = await getServerAxiosInstance(accessToken).post<AggregatedTimeSeriesDateValues>(
        `/emissions/timeseries-data`,
        {
          ...filters,
          resourceNames: filters.resourceNames?.map(resourceNameMapperCallback),
        },
        {
          signal,
        },
      );

      return res;
    },
    [filters, accessToken],
  );

  return { data, loading, error };
};

/*
 * © 2021 Thoughtworks, Inc.
 */

import React, { ReactElement, useState } from 'react';
import { Container } from '@material-ui/core';
import EmissionsMetricsPage from './pages/EmissionsMetricsPage';
import HeaderBar from './layout/HeaderBar';
import MobileWarning from './layout/MobileWarning';
import { ClassNameMap } from '@mui/material';
import { FilterOptions, MaxFilterSelectionsDialog } from './types';
import OnboardingModal from './layout/OnboardingModal/OnboardingModal';
import MaxSubscriptionWarning from './layout/MaxSubscriptionWarning/MaxSubscriptionWarning';
import { useIndexedDBStorage } from './indexedDB/useIndexedDBStorage';

type AppProps = {
  classes: ClassNameMap<'appContainer'>;
};

const App = ({ classes }: AppProps): ReactElement => {
  const [showMaxSelectionsExceededDialog, setShowMaxSelectionsExceededDialog] = useState<MaxFilterSelectionsDialog>({
    show: false,
  });
  const [filterOptions, setFilterOptions] = useState<FilterOptions | undefined>();
  const [filters, setFilters, dbLoading] = useIndexedDBStorage<FilterOptions | undefined>('filters');
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [mobileWarningEnabled, setMobileWarningEnabled] = useState(window.innerWidth < 768);

  const handleWarningClose = () => {
    setMobileWarningEnabled(false);
  };

  if (mobileWarningEnabled) {
    return (
      <Container maxWidth="xl" className={classes.appContainer}>
        <HeaderBar />
        <MobileWarning handleClose={handleWarningClose} />
      </Container>
    );
  }

  if (!filters && !dbLoading) {
    return (
      <>
        <OnboardingModal
          setFilterOptions={setFilterOptions}
          setFilters={setFilters}
          updateMaxSelectionsCountWarning={setShowMaxSelectionsExceededDialog}
        />
        <MaxSubscriptionWarning
          showMaxSelectionsExceededDialog={showMaxSelectionsExceededDialog}
          updateMaxSelectionsCountWarning={setShowMaxSelectionsExceededDialog}
        />
      </>
    );
  }

  return (
    <>
      <EmissionsMetricsPage
        filters={filters}
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
        setFilters={setFilters}
        setFiltersLoading={setFiltersLoading}
        filtersLoading={filtersLoading}
        updateMaxSelectionsCountWarning={setShowMaxSelectionsExceededDialog}
      />
      <MaxSubscriptionWarning
        showMaxSelectionsExceededDialog={showMaxSelectionsExceededDialog}
        updateMaxSelectionsCountWarning={setShowMaxSelectionsExceededDialog}
      />
    </>
  );
};

export default App;

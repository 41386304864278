/*
 * © 2021 Thoughtworks, Inc.
 */

import { Button, Grid } from '@material-ui/core';
import { FunctionComponent, ReactElement, ReactNode } from 'react';
import useStyles from './filterBarStyles';

type FilterBarProps = {
  components: ReactNode[];
  suffixComponent?: ReactElement;
  clearAllOnClick: React.MouseEventHandler<HTMLButtonElement>;
};

const FilterBar: FunctionComponent<FilterBarProps> = ({ components, suffixComponent, clearAllOnClick }) => {
  const classes = useStyles();
  return (
    <div data-testid="filterBar" className={classes.filterHeader}>
      <Grid container direction="row" alignItems="center" justify="center">
        <Grid item xs={12} sm={'auto'}>
          <div className={classes.filterContainer}>
            <div className={classes.filterContainerSection}>
              {components.map((filterComponent, i) => (
                <div key={i} className={classes.filter}>
                  {filterComponent}
                </div>
              ))}
              <Button variant="outlined" onClick={clearAllOnClick}>
                Reset All Filters
              </Button>
            </div>
          </div>
        </Grid>
        {suffixComponent && (
          <Grid item xs={12} sm={'auto'}>
            <div className={classes.filterContainer}>
              <div className={classes.filterContainerSection}>{suffixComponent}</div>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default FilterBar;

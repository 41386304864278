/*
 * © 2021 Thoughtworks, Inc.
 */

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  dateRangeText: {
    color: '#979797',
  },
}));

export default useStyles;

/*
 * © 2021 Thoughtworks, Inc.
 */

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  filterHeader: {
    top: 0,
    position: 'sticky', //make filter bar stick to top of container instead of page
    marginTop: '0px',
    width: '100%',
    backgroundColor: '#fff',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    zIndex: 1199,
  },
  filterContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  filterContainerSection: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    gap: 10,
    padding: '10px 20px',
  },
  filter: {
    resize: 'none',
    minWidth: '200px',
  },
}));

export default useStyles;

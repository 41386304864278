/* eslint @typescript-eslint/ban-ts-comment: "off" */

import React, { ReactElement, SyntheticEvent, useMemo, useState } from 'react';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridOverlay,
  GridRowParams,
  GridSortModel,
  MuiEvent,
} from '@mui/x-data-grid';
import type { FilterModel, RecommendationResult } from '@sede-x/ccf-common';
import { Typography } from '@material-ui/core';
import DashboardCard from '../../../layout/DashboardCard';
import useStyles from './recommendationsTableStyles';
import DateRange from '../../../common/DateRange';
import Tooltip from '../../../common/Tooltip';
import SearchBar from '../SearchBar';
import Forecast from './Forecast/Forecast';
import CustomPagination from './CustomPagination';
import { tableFormatNearZero, tableFormatRawCo2e } from '../../../utils/helpers/transformData';
import { AggregatedTimeSeriesDateValues, Co2eUnit, RecommendationRow } from '../../../types';
import RecommendationsSidePanel from '../RecommendationsSidePanel';
import { co2eUnitAbbreviation } from '../../../utils/helpers';
import { useRecommendations } from '../../../utils/hooks/useRecommendations';
import { currency } from '../../../utils/helpers/units';
import { ALL_KEY } from '../../../common/FilterBar/utils/DropdownConstants';

type Props = {
  filters: FilterModel;
  data: AggregatedTimeSeriesDateValues | null;
};

const getColumns = (co2eUnit: Co2eUnit): GridColDef[] => [
  {
    field: 'cloudProvider',
    headerName: 'Cloud Provider',
    width: 175,
  },
  {
    field: 'accountName',
    headerName: 'Subscription Name',
    flex: 0.75,
  },
  {
    field: 'region',
    headerName: 'Region',
    flex: 0.5,
  },
  {
    field: 'resourceName',
    headerName: 'Resource Name',
    flex: 0.75,
  },
  {
    field: 'recommendationType',
    headerName: 'Recommendation Type',
    flex: 0.75,
  },
  {
    field: 'costSavings',
    headerName: `Potential Cost Savings (${currency.symbol})`,
    flex: 0.75,
    renderCell: (params) => {
      if (typeof params.value === 'number') {
        return tableFormatNearZero(params.value);
      } else {
        return '-';
      }
    },
  },
  {
    field: 'co2eSavings',
    headerName: `Potential Carbon Savings (${co2eUnitAbbreviation[co2eUnit]})`,
    renderCell: (params: GridCellParams) => {
      if (typeof params.value === 'number') {
        return tableFormatRawCo2e(co2eUnit, params.value);
      } else {
        return '-';
      }
    },
    flex: 0.75,
  },
];

export const co2eUnit = Co2eUnit.MetricTonnes;

export const getRecommendationTotals = (recommendations: RecommendationResult[]) => {
  const co2e = recommendations.reduce((curr, value) => (curr += value.co2eSavings), 0);
  const cost = recommendations.reduce((curr, value) => (curr += value.costSavings ?? 0), 0);

  return {
    co2e,
    cost,
  };
};

export const getEmissionTotals = (data: AggregatedTimeSeriesDateValues | null) => {
  const co2e = data?.co2e.reduce((curr, [_, value]) => (curr += value), 0) ?? 0;
  const cost = data?.cost.reduce((curr, [_, value]) => (curr += value), 0) ?? 0;

  return {
    co2e,
    cost,
  };
};

const tooltipMessage =
  'Recommendations are based on cloud usage from the last 14 days, except for GCP CHANGE_MACHINE_TYPE which is from the last 8 days of usage. ' +
  'Estimates marked with a dash (-) do not yet have an appropriate methodology to calculate the associated energy or carbon savings.';

const escapeRegExp = (value: string): string => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

const recommendationMapper = (recommendation: RecommendationResult, index: number) => {
  const recommendationRow = {
    ...recommendation,
    resourceName: getResourceNameFromRecommendation(recommendation),
    id: index,
    co2eUnit: co2eUnit,
    co2eSavings: recommendation.co2eSavings,
  };
  // Replace any undefined values and round numbers to thousandth decimal
  Object.keys(recommendation).forEach((key) => {
    // @ts-ignore
    recommendationRow[key] = recommendationRow[key] ?? '-';
  });
  return recommendationRow;
};

const fieldsToNotFilter = ['resourceId', 'kilowattHourSavings', 'instanceName', 'accountId', 'recommendationDetail'];

export const getResourceNameFromRecommendation = (recommendation: RecommendationResult) => {
  if (recommendation.recommendationType.includes('EBS')) {
    return recommendation.resourceId;
  } else if (recommendation.recommendationType.includes('Lambda')) {
    return recommendation.resourceId?.split(':')[0];
  }

  return recommendation.instanceName;
};

const getSearchFilterPredicate = (searchRegex: RegExp) => (recommendation: RecommendationResult) => {
  return Object.keys(recommendation).some((field: string) => {
    if (!fieldsToNotFilter.includes(field)) {
      // @ts-ignore
      let value = recommendation[field];
      if (field === 'co2eSavings') {
        value = tableFormatRawCo2e(co2eUnit, value);
      } else if (field === 'costSavings') {
        value = tableFormatNearZero(value);
      }
      return searchRegex.test(value?.toString());
    }
  });
};

// Use a hashmap `Map` to check if the value exists instead of a `.some` loop because a time-complexity of O(1) is significantly faster than O(n2) for potentially very large resources & recommendation comparisons.
// Also check if the map has the ALL_KEY as if it does then it should always return true.
const getHasAccountFilterPredicate = (accountsMap: Map<string, string>) => (recommendation: RecommendationResult) => {
  return accountsMap.has(ALL_KEY.toUpperCase()) || accountsMap.has(recommendation.accountId.toUpperCase());
};

const getResourcesFilterPredicate = (resourcesMap: Map<string, string>) => (recommendation: RecommendationResult) => {
  // Have to use name and not id because we don't store ids for the resourceName in the filter, only the selected name
  const recommendationName = getResourceNameFromRecommendation(recommendation);

  return resourcesMap.has(ALL_KEY.toUpperCase()) || resourcesMap.has(recommendationName.toUpperCase());
};

const getRegionsFilterPredicate = (regionsMap: Map<string, string>) => (recommendation: RecommendationResult) => {
  return regionsMap.has(ALL_KEY.toUpperCase()) || regionsMap.has(recommendation.region.toUpperCase());
};

const getMapFromArray = <T extends string>(arr: T[] = []) => {
  const map = new Map<string, string>();

  arr.forEach((value) => {
    map.set(value.toUpperCase(), value);
  });

  return map;
};

const initialPageState = {
  page: 0,
  pageSize: 25,
  sortOrder: [{ field: 'costSavings', sort: 'desc' }],
};

const RecommendationsTable = ({ data, filters }: Props): ReactElement => {
  const [searchBarValue, setSearchBarValue] = useState('');
  const [pageState, setPageState] = useState(initialPageState);
  const accountsMap = useMemo(() => getMapFromArray(filters.subscriptionIds), [filters.subscriptionIds]);
  const resourcesMap = useMemo(() => {
    const map = new Map<string, string>();

    filters.resourceNames.forEach(({ key, value }) => {
      map.set(key.toUpperCase(), value);
    });

    return map;
  }, [filters.resourceNames]);
  const regionsMap = useMemo(() => getMapFromArray(filters.regionNames), [filters.regionNames]);

  const classes = useStyles();
  const recommendations = useRecommendations(filters.subscriptionIds);

  const handlePageSizeChange = (newPageSize: number) => {
    setPageState({ ...pageState, pageSize: newPageSize });
  };

  const resetToInitialPage = (initialPage = 0) => {
    setPageState({ ...pageState, page: initialPage });
  };

  const handleSearchBarChange = (value: string) => {
    setSearchBarValue(value);
    resetToInitialPage();
  };

  const customPaginationComponent = () => <CustomPagination handlePageSizeChange={handlePageSizeChange} />;

  const [selectedRecommendation, setSelectedRecommendation] = useState<RecommendationRow>();

  const handleRowClick = (params: GridRowParams, _event: MuiEvent<SyntheticEvent>) => {
    if (selectedRecommendation && params.row.id === selectedRecommendation.id) {
      setSelectedRecommendation(undefined);
    } else {
      setSelectedRecommendation(params.row as RecommendationRow);
    }
  };

  const rows = useMemo(() => {
    const searchRegex = new RegExp(escapeRegExp(searchBarValue), 'i');
    const filteredRecommendations = recommendations.data;

    return filteredRecommendations
      .map(recommendationMapper)
      .filter(getSearchFilterPredicate(searchRegex))
      .filter(getHasAccountFilterPredicate(accountsMap))
      .filter(getResourcesFilterPredicate(resourcesMap))
      .filter(getRegionsFilterPredicate(regionsMap));
  }, [searchBarValue, recommendations.data, accountsMap, resourcesMap, regionsMap]);

  return (
    <>
      {selectedRecommendation && (
        <RecommendationsSidePanel
          recommendation={selectedRecommendation}
          onClose={() => setSelectedRecommendation(undefined)}
        />
      )}
      <DashboardCard>
        <>
          <Forecast
            emissionTotals={getEmissionTotals(data)}
            recommendationTotals={getRecommendationTotals(recommendations.data)}
          />
          <div className={classes.recommendationsContainer}>
            <Typography className={classes.title}>Recommendations</Typography>
            <div className={classes.dateRangeContainer}>
              <DateRange lookBackPeriodDays={13} />
              <Tooltip message={tooltipMessage} />
            </div>
            <div data-testid="recommendations-data-grid" className={classes.tableContainer}>
              <div className={classes.toolbarContainer}>
                <SearchBar
                  value={searchBarValue}
                  onChange={handleSearchBarChange}
                  clearSearch={() => handleSearchBarChange('')}
                />
              </div>
              <DataGrid
                autoHeight
                rows={rows}
                columns={getColumns(co2eUnit)}
                columnBuffer={6}
                hideFooterSelectedRowCount={true}
                classes={{
                  cell: classes.cell,
                  row: classes.row,
                }}
                onRowClick={handleRowClick}
                disableColumnFilter
                pageSize={pageState.pageSize}
                components={{
                  Toolbar: customPaginationComponent,
                  Pagination: customPaginationComponent,
                  NoRowsOverlay: () => (
                    <GridOverlay>
                      There's no data to display! Expand your search parameters to get started. (Try adding accounts,
                      regions or recommendation types)
                    </GridOverlay>
                  ),
                }}
                onPageSizeChange={handlePageSizeChange}
                page={pageState.page}
                sortModel={pageState.sortOrder as GridSortModel}
                onPageChange={(newPage) => setPageState({ ...pageState, page: newPage })}
                onSortModelChange={(model) => {
                  setPageState({ ...pageState, sortOrder: model });
                }}
              />
            </div>
          </div>
        </>
      </DashboardCard>
    </>
  );
};

export default RecommendationsTable;

import { useEffect, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = ({
  isLoggedIn,
  children,
  isLoading,
  hasError,
}: {
  isLoggedIn: boolean;
  children: ReactElement;
  isLoading: boolean;
  hasError: boolean;
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    if (hasError && !isLoading) {
      navigate('/error');
    }
  }, [hasError, isLoading]);

  if (isLoggedIn) {
    return children;
  }

  return null;
};

export default ProtectedRoute;

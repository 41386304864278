/*
 * © 2021 Thoughtworks, Inc.
 */

import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { TextField, Typography, useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete, {
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  AutocompleteInputChangeReason,
} from '@material-ui/lab/Autocomplete';

import { DropdownOption, MaxFilterSelectionsDialog } from '../../types';
import useStyles from './filterDropdownStyles';
import { ALL_KEY } from '../FilterBar/utils/DropdownConstants';

interface FilterDropdownProps {
  allLabel: string;
  id: string;
  displayValue: string;
  options?: DropdownOption[];
  selections?: DropdownOption[];
  updateSelections: (selections: DropdownOption[]) => void;
  popperWidth?: number;
  maxSelectionsCount?: number;
  updateMaxSelectionsCountWarning?: ({ show, label }: MaxFilterSelectionsDialog) => void;
}

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  return (
    <Typography component="li" {...dataSet} noWrap style={inlineStyle}>
      {dataSet}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(function ListboxComponent(
  props,
  ref,
) {
  const { children, ...other } = props;
  const itemData: React.ReactElement[] = [];
  (children as React.ReactElement[]).forEach((item: React.ReactElement & { children?: React.ReactElement[] }) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactElement) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const FilterDropdown: FunctionComponent<FilterDropdownProps> = ({
  allLabel,
  id,
  displayValue,
  options = [],
  selections = [],
  updateSelections,
  maxSelectionsCount,
  updateMaxSelectionsCountWarning,
  popperWidth,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const classes = useStyles({ popperWidth });
  const [inputText, setInputText] = useState<string>('');

  const renderOption = (option: DropdownOption, state: AutocompleteRenderOptionState) => (
    <div className={classes.innerOption} title={option.name}>
      <Checkbox
        color="primary"
        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        checkedIcon={<CheckBoxIcon fontSize="small" />}
        inputProps={{ role: `checkbox-${option.key}` }}
        checked={state.selected}
      />
      {option.name}
    </div>
  );

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      className={classes.textField}
      variant="outlined"
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      {...params}
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <Typography variant={'button'} align={'center'} className={classes.inputLabel}>
            {isFocused ? '' : displayValue}
          </Typography>
        ),
      }}
    />
  );

  const allValue = {
    key: ALL_KEY,
    name: `All ${allLabel}`,
  };

  const onChange = (
    _: React.ChangeEvent<unknown>,
    selections: DropdownOption[],
    __: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<DropdownOption>,
  ) => {
    if (details?.option.key === ALL_KEY) {
      const noneChecked = selections.length === 0;

      if (noneChecked) {
        updateSelections([]);
        return;
      }
      updateSelections([allValue]);
      return;
    }

    const filteredSelections = (selections as DropdownOption[]).filter((x) => x.key !== ALL_KEY);

    if (maxSelectionsCount && filteredSelections.length > maxSelectionsCount) {
      updateMaxSelectionsCountWarning?.({ show: true, label: allLabel });
    } else {
      updateSelections(filteredSelections);
    }
  };

  const onInputChange = (_: ChangeEvent, value: string, reason: AutocompleteInputChangeReason) => {
    if (reason === 'reset') {
      return;
    }

    setInputText(value);
  };

  const onClose = () => {
    setInputText('');
  };

  // exclude `All` value if maxSelectionsCount is set
  const modifiedOptions = maxSelectionsCount ? [...options] : [allValue, ...options];

  return (
    <Autocomplete
      id={id}
      classes={{ listbox: classes.listBox, popper: classes.popper, option: classes.option }}
      inputValue={inputText}
      onInputChange={onInputChange}
      onClose={onClose}
      multiple
      disableCloseOnSelect
      disableClearable
      size="small"
      options={modifiedOptions}
      value={selections}
      onChange={onChange}
      getOptionLabel={(option: DropdownOption) => option.name}
      getOptionSelected={(option: DropdownOption, value: DropdownOption) => option.key === value.key}
      renderOption={renderOption}
      renderTags={() => null}
      renderInput={renderInput}
      ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
    />
  );
};

export default FilterDropdown;

/*
 * © 2021 Thoughtworks, Inc.
 */

export interface ClientConfig {
  CURRENT_PROVIDERS: {
    key: string;
    name: string;
  }[];
  PREVIOUS_YEAR_OF_USAGE: boolean;
  DATE_RANGE: {
    VALUE: string;
    TYPE: string;
  };
  GROUP_BY: string;
  PAGE_LIMIT: string;
  BASE_URL: string;
  REACT_APP_DISABLE_AUTH: boolean;
  MINIMAL_DATE_AGE: string;
  START_DATE: string;
  END_DATE: string;
  DISABLE_CACHE: boolean;
  DISABLE_FORECAST_VALIDATION: boolean;
  TEST_MODE: boolean;
}

let previousYearOfUsage =
  !!process.env.REACT_APP_PREVIOUS_YEAR_OF_USAGE && process.env.REACT_APP_PREVIOUS_YEAR_OF_USAGE !== 'false';
let groupBy = process.env.REACT_APP_GROUP_BY || 'day';
let pageLimit = process.env.REACT_APP_PAGE_LIMIT || '50000';
let baseUrl = process.env.REACT_APP_BASE_URL || '/api';
let minDateAge = process.env.REACT_APP_MINIMAL_DATE_AGE || '0';
let disableForecastValidation = process.env.REACT_APP_DISABLE_FORECAST_VALIDATION === 'true';

// For local development / integration testing
if (process.env.REACT_APP_TEST_MODE === 'true') {
  previousYearOfUsage = true;
  groupBy = 'month';
  pageLimit = '1000';
  baseUrl = 'http://127.0.0.1:3000/api';
  minDateAge = '0';
  disableForecastValidation = false;
}

const appConfig: ClientConfig = {
  CURRENT_PROVIDERS: [{ key: 'azure', name: 'Azure' }],
  PREVIOUS_YEAR_OF_USAGE: previousYearOfUsage,
  DATE_RANGE: {
    VALUE: process.env.REACT_APP_DATE_RANGE_VALUE || '12',
    TYPE: process.env.REACT_APP_DATE_RANGE_TYPE || 'months',
  },
  REACT_APP_DISABLE_AUTH: process.env.REACT_APP_DISABLE_AUTH === 'true',
  GROUP_BY: groupBy,
  PAGE_LIMIT: pageLimit,
  BASE_URL: baseUrl,
  MINIMAL_DATE_AGE: minDateAge,
  START_DATE: process.env.REACT_APP_START_DATE ?? '',
  END_DATE: process.env.REACT_APP_END_DATE ?? '',
  DISABLE_CACHE: process.env.REACT_APP_DISABLE_CACHE === 'true',
  DISABLE_FORECAST_VALIDATION: disableForecastValidation,
  TEST_MODE: process.env.REACT_APP_TEST_MODE === 'true',
};

export default appConfig;

import React, { useState, useEffect, Dispatch, SetStateAction, useMemo } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Paper,
  Checkbox,
} from '@mui/material';

import { DropdownOption, FilterOptions, ResourceEmissionData, ResourceTableData, Resources } from 'src/types';
import { ALL_KEY } from 'src/common/FilterBar/utils/DropdownConstants';
import { SortDir, ResourceTableColumns, FilterModel } from '@sede-x/ccf-common';
import EmissionsChange from './EmissionsChange';
import EnhancedTableHead from './EnhancedTableHead';
import { useResourcesFilter } from 'src/utils/hooks/useResourcesFilter';
import Header from 'src/common/Header/Header';
import EmissionsValue from './EmissionsValue';
import { searchLimit, useResourcesSearch } from '../../../utils/hooks/useResourcesSearch';
import SearchBar from '../../../common/SearchBar';

type ResourceTableProps = {
  filters: FilterModel;
  setFilters: Dispatch<SetStateAction<FilterOptions | undefined>>;
};

const rowMapper = (datum: ResourceEmissionData) => {
  return {
    subscriptionName: datum.subscription.name,
    subscriptionId: datum.subscription.id,
    resourceGroupId: datum.resourceGroupId,
    resourceId: datum.resourceId,
    resourceName: datum.resourceName,
    regionName: datum.regionName,
    serviceName: datum.serviceName,
    currentEmissions: datum.currentEmissions,
    previousEmissions: datum.previousEmissions,
    change: datum.change || 0,
    changePercentage: datum.changePercentage || 0,
  };
};

export const cellSx = {
  maxWidth: '300px',
  width: '300px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textWrap: 'nowrap',
};

export const emissionFixedDecimals = 2;

const ResourceTable = ({ setFilters, filters }: ResourceTableProps) => {
  const pageOptions = [5, 10, 25, 50, 100];
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(pageOptions[0]);
  const [sortBy, setSortBy] = useState<ResourceTableColumns>('change');
  const [sortDir, setSortDir] = useState<SortDir>('desc');
  const [totalCount, setTotalCount] = useState<number>(0);
  const [rows, setRows] = useState<ResourceTableData[] | undefined>();
  const [searchValue, setSearchValue] = useState<string>('');
  const isAllSelected = filters?.resourceNames?.some((resource) => resource.key === ALL_KEY);
  const newFilters = useMemo(() => ({ ...filters, resourceNames: [{ key: ALL_KEY, value: ALL_KEY }] }), [filters]);
  const { data, loading } = useResourcesFilter(newFilters, page, limit, sortBy, sortDir);
  const { data: searchData } = useResourcesSearch(searchValue, newFilters, page, limit, sortBy, sortDir);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortBy = (value: ResourceTableColumns) => {
    setSortBy(value);
  };

  const handelSortDir = (value: SortDir) => {
    setSortDir(value);
  };

  const updateData = (newData: Resources) => {
    setRows(newData?.resources?.map(rowMapper));
    setTotalCount(newData?.totalCount);
  };

  const resetData = () => updateData(data);

  const clearSearch = () => {
    setSearchValue('');
    resetData();
  };

  const updateSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value.length < searchLimit) {
      clearSearch();
    } else {
      // We always want to reset to the first page if a new search is being started
      setPage(0);
    }

    setSearchValue(value);
  };

  const tonsToKg = (tons: number): number => {
    return tons * 1000;
  };

  const handleSelectAllClick = () => {
    const allChecked = filters.resourceNames?.[0].key === ALL_KEY;

    if (allChecked) {
      setFilters((prev) => ({
        ...prev,
        resources: [],
      }));
      return;
    }

    setFilters((prev) => ({
      ...prev,
      resources: [
        {
          key: ALL_KEY,
          name: ALL_KEY,
        },
      ],
    }));
  };

  const handleClick = (isChecked: boolean, option: DropdownOption) => {
    if (isAllSelected) {
      setFilters((prev) => {
        return {
          ...prev,
          resources: [option],
        };
      });

      return;
    }

    setFilters((prev) => {
      return {
        ...prev,
        resources: isChecked ? prev.resources.filter((x) => x.key !== option.key) : [...prev.resources, option],
      };
    });
  };

  useEffect(() => {
    if (data && !loading && !searchValue.trim().length) {
      setRows(data.resources.map(rowMapper));
      setTotalCount(data.totalCount);
    }
  }, [data, loading]);

  useEffect(() => {
    updateData(searchData);
  }, [searchData]);

  return (
    <Box sx={{ paddingBottom: '1.75rem' }}>
      <Paper>
        <Header text="Resources" />
        <Box sx={{ padding: '1rem', display: 'flex', flexDirection: 'column', gap: 1 }}>
          <SearchBar value={searchValue} onChange={updateSearch} clearSearch={clearSearch} />
          <span>Note: Requires 3 or more characters to search</span>
        </Box>
        <TableContainer
          sx={{
            borderTop: '1px solid rgba(224, 224, 224, 1)',
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
          }}
        >
          <Table stickyHeader sx={{ tableLayout: 'fixed' }}>
            <EnhancedTableHead
              selectAllResources={isAllSelected}
              sortBy={sortBy}
              sortDir={sortDir}
              handleSortBy={handleSortBy}
              handleSortDir={handelSortDir}
              handleSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {rows?.length > 0 ? (
                rows.map((row) => {
                  const {
                    resourceGroupId,
                    resourceId,
                    subscriptionName,
                    resourceName,
                    regionName,
                    serviceName,
                    currentEmissions,
                    previousEmissions,
                    change,
                    changePercentage,
                  } = row;

                  const isItemSelected =
                    isAllSelected || filters.resourceNames?.some((resource) => resource.key === resourceId);

                  return (
                    <TableRow
                      key={resourceId}
                      hover
                      onClick={() => handleClick(isItemSelected, { key: resourceId, name: resourceName })}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} />
                      </TableCell>
                      <TableCell align="center" sx={cellSx} title={subscriptionName}>
                        {subscriptionName}
                      </TableCell>
                      <TableCell align="center" sx={cellSx} title={resourceGroupId}>
                        {resourceGroupId}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          ...cellSx,
                          width: '250px',
                          maxWidth: '250px',
                        }}
                        title={resourceName}
                      >
                        {resourceName}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          ...cellSx,
                          width: '150px',
                          maxWidth: '150px',
                        }}
                        title={regionName}
                      >
                        {regionName}
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          ...cellSx,
                          width: '200px',
                          maxWidth: '200px',
                        }}
                        title={serviceName}
                      >
                        {serviceName}
                      </TableCell>
                      <EmissionsValue emission={tonsToKg(currentEmissions)} />
                      <EmissionsValue emission={tonsToKg(previousEmissions)} />
                      <EmissionsChange
                        value={tonsToKg(change)}
                        formatter={(value) => value.toExponential(emissionFixedDecimals)}
                      />
                      <EmissionsChange
                        value={changePercentage}
                        unit="%"
                        formatter={(value) => value.toFixed(emissionFixedDecimals)}
                      />
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={10} sx={{ py: 3 }}>
                    No resource change data available for the filters chosen.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={pageOptions}
          component="div"
          count={totalCount}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          showFirstButton={true}
          showLastButton={true}
        />
      </Paper>
    </Box>
  );
};

export default ResourceTable;

import { useAxiosRequest } from './useAxiosRequest';
import { useAuthentication } from './useAuthentication';
import { getServerAxiosInstance } from '../axios';
import { SortDir, ResourceTableColumns } from '@sede-x/ccf-common';
import { FilterModel } from '@sede-x/ccf-common';
import { resourceNameMapperCallback } from '../../pages/EmissionsMetricsPage/utils';

export const useResourcesFilter = (
  filters: FilterModel,
  page: number,
  limit: number,
  sortBy: ResourceTableColumns,
  sortDir: SortDir,
) => {
  const { authorizeResult } = useAuthentication();
  const accessToken = authorizeResult?.accessToken;

  const { data, loading, error } = useAxiosRequest(
    async (signal) => {
      const res = await getServerAxiosInstance(accessToken).post(
        `/emissions/resources`,
        {
          ...filters,
          resourceNames: filters.resourceNames?.map(resourceNameMapperCallback),
          sortBy,
          sortDir,
          page,
          limit,
        },
        {
          signal,
        },
      );

      return res;
    },
    [filters, page, limit, sortBy, sortDir, accessToken],
  );

  return { data, loading, error };
};

import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import { Grid, TableCell } from '@mui/material';
import { cellSx } from './ResourceTable';

const EmissionsChange = ({
  value,
  unit,
  formatter = (val) => val.toString(),
}: {
  value: number;
  unit?: '%';
  formatter: (value: number) => string;
}) => {
  if (!value) {
    return (
      <TableCell
        align="center"
        sx={{
          ...cellSx,
          maxWidth: '150px',
          width: '150px',
        }}
      >
        0
      </TableCell>
    );
  }

  return (
    <TableCell
      align="center"
      sx={{
        ...cellSx,
        color: value > 0 ? '#800000' : '#138808',
        maxWidth: '150px',
        width: '150px',
      }}
      title={unit ? formatter(value) + unit : formatter(value)}
    >
      <Grid container flexDirection="row">
        <Grid item xs={4}>
          {value > 0 ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />}
        </Grid>
        <Grid item xs={8} sx={{ textAlign: 'left' }}>
          {formatter(value)}
          {unit}
        </Grid>
      </Grid>
    </TableCell>
  );
};

export default EmissionsChange;

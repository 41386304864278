/*
 * © 2021 Thoughtworks, Inc.
 */

import React, { FunctionComponent, ReactElement, useState } from 'react';
import { BreakdownType, FilterModel, TimeseriesBreakdown } from '@sede-x/ccf-common';
import SelectDropdown from '../../../common/SelectDropdown';
import NoDataMessage from '../../../common/NoDataMessage';
import DashboardCard from '../../../layout/DashboardCard';
import ApexBarChart from './ApexBarChart';
import useStyles from './emissionsBreakdownStyles';
import { useEmissionsBreakdownTotalCo2e } from '../../../utils/hooks/useEmissionsBreakdown';
import { DropdownOption } from '../../../types';

type EmissionsBreakdownContainerProps = {
  filters: FilterModel;
  accounts: DropdownOption[] | undefined;
};

export const BreakdownChartTypeMap: Record<BreakdownType, string> = {
  regionName: 'region',
  serviceName: 'service',
  accountId: 'subscription',
  resourceGroupId: 'resource group',
  resourceName: 'resource',
};

const getAccountsMappedData = (accounts: DropdownOption[], emissionsBreakdownData: TimeseriesBreakdown) => {
  const data = accounts
    ? Object.entries(emissionsBreakdownData ?? {}).reduce((prev, [key, value]) => {
        const account = accounts.find((x) => x.key === key);

        if (!account) {
          return {};
        }

        return {
          ...prev,
          [account.name]: value,
        };
      }, {})
    : {};

  return data;
};

const EmissionsBreakdownCard: FunctionComponent<EmissionsBreakdownContainerProps> = ({
  filters,
  accounts,
}): ReactElement => {
  const classes = useStyles();
  const [chartType, setChartType] = useState<BreakdownType>('regionName');

  const { data: emissionsBreakdownDataTCo2, loading: emissionsLoading } = useEmissionsBreakdownTotalCo2e(
    filters,
    chartType,
  );

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setChartType(event.target.value as BreakdownType);
  };

  if (emissionsLoading) {
    return (
      <NoDataMessage
        isHalf
        title="Emissions Breakdown"
        isLoading={emissionsLoading}
        loadingMessage={'Loading emissions breakdown...'}
      />
    );
  }

  const dropdownOptions = Object.keys(BreakdownChartTypeMap).map((key) => {
    return {
      key,
      value: BreakdownChartTypeMap[key as keyof typeof BreakdownChartTypeMap],
    };
  });

  const data =
    chartType === 'accountId'
      ? getAccountsMappedData(accounts, emissionsBreakdownDataTCo2)
      : emissionsBreakdownDataTCo2;

  return (
    <DashboardCard isHalf>
      <>
        <div className={classes.topContainer} id="emissionsBreakdownContainer">
          <p className={classes.title}>Emissions Breakdown</p>
          <SelectDropdown
            id="breakdown"
            value={chartType}
            dropdownOptions={dropdownOptions}
            handleChange={handleChange}
          />
        </div>
        <ApexBarChart data={data} dataType={chartType} />
      </>
    </DashboardCard>
  );
};

export default EmissionsBreakdownCard;

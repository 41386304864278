import { Account } from '@sede-x/ccf-common';

import { ServiceResult } from '../../types';
import { useAxiosRequest } from './useAxiosRequest';
import { useAuthentication } from './useAuthentication';
import { getServerAxiosInstance } from '../axios';

export const useAccountFilter = (): ServiceResult<Account[]> => {
  const { authorizeResult } = useAuthentication();
  const accessToken = authorizeResult?.accessToken;

  const { data, loading, error } = useAxiosRequest(
    async (signal) => {
      const res = await getServerAxiosInstance(accessToken).get<Account[]>(`/emissions/filters/account`, {
        signal,
      });

      return res;
    },
    [accessToken],
  );

  return { data: data ?? [], loading, error };
};

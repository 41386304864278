/*
 * © 2021 Thoughtworks, Inc.
 */

import React, { ReactElement } from 'react';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Route, Routes } from 'react-router-dom';
import HeaderBar from './layout/HeaderBar';
import { Login } from './pages/Login';
import { AuthCallback } from './pages/AuthCallback';
import ProtectedRoute from './ProtectedRoute';
import App from './App';
import { useAuthentication } from './utils/hooks/useAuthentication';

const ApplicationRoutes = (): ReactElement => {
  const { isLoggedIn, login, isLoading, hasError } = useAuthentication();

  const useStyles = makeStyles(() => ({
    appContainer: {
      padding: 0,
      height: 'calc(100vh - 65px)',
    },
  }));

  const classes = useStyles();

  return (
    <>
      <HeaderBar />
      <Container maxWidth={false} className={classes.appContainer}>
        <Routes>
          <Route path="/" element={<Login login={login} />} />
          <Route
            path="/auth/callback"
            element={<AuthCallback isLoading={isLoading} hasLoginError={hasError} isLoggedIn={isLoggedIn} />}
          />
          <Route
            path="/app/*"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn} hasError={hasError} isLoading={isLoading}>
                <App classes={classes} />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Container>
    </>
  );
};

export default ApplicationRoutes;

import axios from 'axios';
import appConfig from '../Config';

export const getServerAxiosInstance = (accessToken: string) =>
  axios.create({
    baseURL: appConfig.BASE_URL,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

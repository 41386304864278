import { TableCell } from '@mui/material';
import { cellSx, emissionFixedDecimals } from './ResourceTable';

const EmissionValue = ({ emission }: { emission: number }) => {
  return (
    <TableCell
      align="center"
      sx={{
        ...cellSx,
        maxWidth: '150px',
        width: '150px',
      }}
      title={emission.toString()}
    >
      {emission ? <>{emission.toExponential(emissionFixedDecimals)}</> : <span>0</span>}
    </TableCell>
  );
};

export default EmissionValue;

/*
 * © 2021 Thoughtworks, Inc.
 */

import React, { FunctionComponent, ReactElement } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { FilterProps, FilterSelectionsMaxCount, MaxFilterSelectionsDialog } from '../../types';

type MaxSubscriptionWarningProps = {
  showMaxSelectionsExceededDialog: MaxFilterSelectionsDialog;
  updateMaxSelectionsCountWarning: NonNullable<FilterProps['updateMaxSelectionsCountWarning']>;
};

const MaxSubscriptionWarning: FunctionComponent<MaxSubscriptionWarningProps> = ({
  showMaxSelectionsExceededDialog,
  updateMaxSelectionsCountWarning,
}): ReactElement => {
  return (
    <Dialog
      open={showMaxSelectionsExceededDialog.show}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{`Max number of ${showMaxSelectionsExceededDialog.label} exceeded!`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`You may select up to ${
            FilterSelectionsMaxCount[showMaxSelectionsExceededDialog.label as keyof typeof FilterSelectionsMaxCount]
          } ${showMaxSelectionsExceededDialog.label} only.
            Please unselect ${showMaxSelectionsExceededDialog.label} from the list in order to select other ${
            showMaxSelectionsExceededDialog.label
          }.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => updateMaxSelectionsCountWarning({ show: false })} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MaxSubscriptionWarning;

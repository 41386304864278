/*
 * © 2021 Thoughtworks, Inc.
 */

import React, { FunctionComponent, ReactElement } from 'react';
import clsx from 'clsx';
import ForwardIcon from '@material-ui/icons/Forward';
import { Typography } from '@material-ui/core';
import useStyles from './forecastStyles';
import ForecastCard from '../ForecastCard';
import ForecastEquivalencyCard from '../ForecastEquivalencyCard';
import { calculatePercentChange, formattedNumberWithCommas, co2eUnitMultiplier } from '../../../../utils/helpers';
import { co2eUnit } from '../RecommendationsTable';

export type ForecastProps = {
  emissionTotals: {
    co2e: number;
    cost: number;
  };
  recommendationTotals: {
    co2e: number;
    cost: number;
  };
};

const Forecast: FunctionComponent<ForecastProps> = ({ emissionTotals, recommendationTotals }): ReactElement => {
  const classes = useStyles();

  const forecastMultiplier = co2eUnitMultiplier[co2eUnit];

  const currentCo2eFormatted = formattedNumberWithCommas(emissionTotals.co2e * forecastMultiplier);
  const currentCostFormatted = `$${formattedNumberWithCommas(emissionTotals.cost)}`;

  const projectedSavingsCo2e = emissionTotals.co2e - recommendationTotals.co2e;
  const projectedSavingsCost = emissionTotals.cost - recommendationTotals.cost;

  const formatProjectedSavings = (projectedSavings: number): string =>
    projectedSavings > 0 ? formattedNumberWithCommas(projectedSavings) : '0';

  const projectedCo2eFormatted = formatProjectedSavings(projectedSavingsCo2e * forecastMultiplier);

  const projectedCostFormatted = `$${formatProjectedSavings(projectedSavingsCost)}`;

  const getPercentChange = (oldAmount: number, newAmount: number): number =>
    newAmount > 0 ? calculatePercentChange(oldAmount, newAmount) : 0;

  const co2ePercentChange = getPercentChange(emissionTotals.co2e, projectedSavingsCo2e);
  const costPercentChange = getPercentChange(emissionTotals.cost, projectedSavingsCost);

  const monthlyCostSavings = `$${formattedNumberWithCommas(recommendationTotals.cost)}`;
  const treeSeedlings = formattedNumberWithCommas(recommendationTotals.co2e * 16.5337915448, 0);

  return (
    <>
      <Typography className={classes.title}>Forecast</Typography>
      <div className={classes.forecastContainer}>
        <ForecastCard
          title="Previous Total"
          co2eSavings={currentCo2eFormatted}
          costSavings={currentCostFormatted}
          co2eUnit={co2eUnit}
          id="last-total"
        />
        <ForwardIcon className={classes.icon} />
        <ForecastCard
          title="Projected Total"
          co2eSavings={projectedCo2eFormatted}
          costSavings={projectedCostFormatted}
          co2ePercentChange={co2ePercentChange}
          costPercentChange={costPercentChange}
          co2eUnit={co2eUnit}
          id="projected-total"
        />
        <div className={clsx(classes.icon, classes.equalSign)}>=</div>
        <ForecastEquivalencyCard
          title="Monthly Savings Equal To"
          treeSeedlings={treeSeedlings}
          yearCostSavings={monthlyCostSavings}
        />
      </div>
    </>
  );
};

export default Forecast;

/*
 * © 2021 Thoughtworks, Inc.
 */

import { RecommendationResult } from '@sede-x/ccf-common';
import { useAuthentication } from './useAuthentication';
import { useAxiosRequest } from './useAxiosRequest';
import { getServerAxiosInstance } from '../axios';
import { ServiceResult } from '../../types';

export const useRecommendations = (subscriptionIds: string[]): ServiceResult<RecommendationResult[]> => {
  const { authorizeResult } = useAuthentication();
  const accessToken = authorizeResult?.accessToken;

  const { data, loading, error } = useAxiosRequest<RecommendationResult[]>(
    async (signal) => {
      const res = await getServerAxiosInstance(accessToken).post<RecommendationResult[]>(
        `/recommendations`,
        {
          subscriptionIds,
        },
        {
          signal,
        },
      );

      return res;
    },
    [subscriptionIds, accessToken],
  );

  return { data: data ?? [], loading, error };
};

/*
 * © 2021 Thoughtworks, Inc.
 */

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  option: {
    padding: 0,
  },
  innerOption: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  inputLabel: {
    textTransform: 'none',
  },
  popper: ({ popperWidth }: { popperWidth: number | undefined }) =>
    popperWidth
      ? {
          width: `${popperWidth}px !important`,
        }
      : {},
  listBox: {
    overflowWrap: 'anywhere', //this works but cuts words out at the end, try to find better solution
    padding: 0,
    '& ul': {
      margin: 0,
      padding: 0,
    },
  },
  textField: {
    backgroundColor: theme.palette.background.paper,
    overflow: 'none',
    borderRadius: theme.shape.borderRadius,
    height: theme.spacing(5),
  },
}));

export default useStyles;

/*
 * © 2021 Thoughtworks, Inc.
 */

import React, { ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import { Grid } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { createStyles, Theme } from '@material-ui/core/styles';
import { AxiosError } from 'axios';

export type ErrorState = {
  statusText?: string;
  status?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cloudIcon: {
      fontSize: '175px',
      color: theme.palette.primary.main,
    },
    errorStatus: {
      fontSize: '36px',
      textAlign: 'center',
    },
    errorMessage: {
      fontSize: '18px',
      textAlign: 'center',
    },
  }),
);

const DEFAULT_ERROR = {
  status: '520',
  statusText: 'Unknown Error',
};

export const formatAxiosError = (e: AxiosError): ErrorState => {
  return e.response
    ? {
        status: e.response.status.toString(),
        statusText: e.response.statusText,
      }
    : DEFAULT_ERROR;
};

interface ErrorPageProps {
  errorMessage?: string | ReactElement;
  status?: string;
  statusText: string;
}

const ErrorPage = (props: ErrorPageProps): ReactElement<ErrorPageProps> => {
  const location = useLocation();
  let statusText, status;

  if (location.state && location.state.status && location.state.statusText) {
    statusText = location.state.statusText;
    status = location.state.status.toString();
  } else if (props.status && props.statusText) {
    statusText = props.statusText;
    status = props.status;
  } else {
    statusText = DEFAULT_ERROR.statusText;
    status = DEFAULT_ERROR.status;
  }

  const classes = useStyles();

  return (
    <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ height: '100%' }}>
      <CloudOffIcon className={classes.cloudIcon} />
      <div data-testid="error-page">
        <h1 className={classes.errorStatus}>
          {status} {statusText}
        </h1>
        <div data-testid="error-message" className={classes.errorMessage}>
          {props.errorMessage ? props.errorMessage : 'Something has gone wrong, Please try again later'}
        </div>
      </div>
    </Grid>
  );
};

export default ErrorPage;

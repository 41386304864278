/*
 * © 2021 Thoughtworks, Inc.
 */

import React, { Dispatch, FunctionComponent, ReactElement, SetStateAction, useMemo, useState } from 'react';
import { Button, Container, Modal, Typography } from '@material-ui/core';
import DashboardCard from '../DashboardCard';
import useStyles from './onboardingModalStyles';
import AccountFilter from '../../pages/EmissionsMetricsPage/EmissionsFilterBar/Filters/AccountFilter/AccountFilter';
import ErrorPage from '../ErrorPage';
import { FilterOptions, FilterProps } from '../../types';
import LoadingMessage from '../../common/LoadingMessage/LoadingMessage';
import {
  getDefaultFilterDateRange,
  mapAccountIdsToDropdownOptions,
  mapFilterResponseToDropdownOptions,
} from '../../pages/EmissionsMetricsPage/utils';
import { FilterModelResponse } from '@sede-x/ccf-common';
import { ALL_KEY } from '../../common/FilterBar/utils/DropdownConstants';
import { useAccountFilter } from '../../utils/hooks/useAccountFilter';
import { useAuthentication } from '../../utils/hooks/useAuthentication';
import { getServerAxiosInstance } from '../../utils/axios';

type OnboardingModalProps = Omit<FilterProps, 'filters'> & {
  updateMaxSelectionsCountWarning: NonNullable<FilterProps['updateMaxSelectionsCountWarning']>;
  handleClose?: () => void;
  setFilterOptions: Dispatch<SetStateAction<FilterOptions | undefined>>;
};

const OnboardingModal: FunctionComponent<OnboardingModalProps> = ({
  updateMaxSelectionsCountWarning,
  setFilters,
  setFilterOptions,
}): ReactElement => {
  const classes = useStyles();
  const [modalFilters, setModalFilters] = useState<FilterOptions | undefined>();
  const [loading, setLoading] = useState(false);
  const isOkButtonDisabled =
    !modalFilters?.accounts || modalFilters.accounts.length === 0 || modalFilters.accounts.length > 10;
  const { data, loading: accountFilterLoading } = useAccountFilter();
  const { authorizeResult } = useAuthentication();
  const accessToken = authorizeResult?.accessToken;

  const options = useMemo(() => ({ accounts: mapAccountIdsToDropdownOptions(data) }), [data]);

  // Check if users are not added to the JSON by looking at authorizeResult variable
  if (!accountFilterLoading && !loading && !authorizeResult) {
    // return error message if authentication error
    return (
      <ErrorPage
        errorMessage={
          <>
            Contact <a href="mailto:filippo.galeazzi@shell.com">Filippo Galeazzi</a> to get access to the application.
          </>
        }
        statusText="Unauthorized"
        status="401"
      />
    );
  }

  if (accountFilterLoading || loading) {
    return <LoadingMessage message="Loading..." />;
  }

  return (
    <Modal open>
      <Container className={classes.container}>
        <DashboardCard noPadding containerClass={classes.card}>
          <div className={classes.contentContainer}>
            {data?.length === 0 && ( // Check if data is empty
              <Typography className={classes.title}>No subscription data available. Please try again later.</Typography>
            )}
            {data?.length > 0 && ( // Render modal content if data is available
              <>
                <Typography className={classes.title}>
                  Select the Azure Subscriptions you want to see Emissions for.
                </Typography>
                <div className={classes.filter}>
                  <AccountFilter
                    filters={modalFilters}
                    options={options}
                    updateSelections={(selections) => {
                      setModalFilters((prev) => ({
                        ...prev,
                        accounts: selections,
                      }));
                    }}
                    updateMaxSelectionsCountWarning={updateMaxSelectionsCountWarning}
                  />
                </div>
                <Typography>Note: A max of 10 subscriptions can be selected.</Typography>
                <Button
                  disabled={isOkButtonDisabled}
                  onClick={async () => {
                    setLoading(true);

                    const subscriptionIds = modalFilters.accounts.map((x) => x.key);
                    const { data: filterData } = await getServerAxiosInstance(accessToken).post<FilterModelResponse>(
                      `/emissions/account/filters`,
                      {
                        subscriptionIds,
                      },
                    );
                    setLoading(false);

                    const filterOptions = mapFilterResponseToDropdownOptions({
                      ...filterData,
                      accounts: data,
                    });

                    const allValue = {
                      key: ALL_KEY,
                      name: 'all',
                    };

                    setFilters({
                      cloudProviders: [
                        {
                          key: 'azure',
                          name: 'Azure',
                        },
                      ],
                      accounts: mapAccountIdsToDropdownOptions(filterData.accounts),
                      resourceGroups: [allValue],
                      resources: [allValue],
                      regions: [allValue],
                      services: [allValue],
                      dateRange: getDefaultFilterDateRange(),
                    });
                    setFilterOptions(filterOptions);
                  }}
                >
                  Ok
                </Button>
              </>
            )}
          </div>
        </DashboardCard>
      </Container>
    </Modal>
  );
};

export default OnboardingModal;

/*
 * © 2021 Thoughtworks, Inc.
 */

import React, { FunctionComponent } from 'react';
import { Button, ButtonGroup } from '@material-ui/core';
import { FilterProps, TimeFilterValue } from '../../../../../types';
import useStyles from './monthFilterStyles';
import moment from 'moment';

const MonthFilter: FunctionComponent<FilterProps> = ({ filters, setFilters }) => {
  const classes = useStyles();
  const timeframes: { [label: string]: number } = {
    '1W': 0.25,
    '1M': 1,
    '2M': 2,
    All: 36,
  };

  const calculateStartDate = (time: number): string => {
    if (process.env.REACT_APP_CURRENT_FILTER_DATE) {
      const startDate = new Date(process.env.REACT_APP_CURRENT_FILTER_DATE);
      time === 0.25 ? startDate.setDate(startDate.getDate() - 7) : startDate.setMonth(startDate.getMonth() - time);
      const formattedStartDate = startDate.toISOString().split('T')[0];

      return formattedStartDate;
    } else {
      return time === 0.25
        ? moment()
            .subtract(time * 4, 'weeks')
            .format('YYYY-MM-DD')
        : moment().subtract(time, 'months').format('YYYY-MM-DD');
    }
  };

  return (
    <>
      <ButtonGroup className={classes.buttonGroup}>
        {Object.keys(timeframes).map((label) => {
          const time = timeframes[label] as TimeFilterValue;
          const isCurrentTime = filters?.dateRange?.timeFilterValue === time;

          return (
            <Button
              disableElevation
              key={label}
              variant={isCurrentTime ? 'contained' : undefined}
              color={isCurrentTime ? 'primary' : 'default'}
              onClick={() => {
                setFilters({
                  ...filters,
                  dateRange: {
                    timeFilterValue: time,
                    startDate: calculateStartDate(time),
                  },
                });
              }}
              className={classes.button}
            >
              {label}
            </Button>
          );
        })}
      </ButtonGroup>
    </>
  );
};

export default MonthFilter;

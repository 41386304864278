/*
 * © 2021 Thoughtworks, Inc.
 */

import { DropdownFilterOptions, DropdownOption, FilterOptions, filterLabels } from '../../../types';
import { ALL_KEY } from './DropdownConstants';

export function numSelectedLabel(label: string, length: number | undefined, totalLength: number): string {
  return `${label}: ${length} of ${totalLength}`;
}

export const getCurrentFilterLabel = (type: DropdownFilterOptions) => {
  const label = filterLabels[type];

  if (!label) {
    throw new Error(`Missing label for filter type ${type}. Please add a label mapping.`);
  }

  return label;
};

export const getCurrentFilterValues = (
  type: DropdownFilterOptions,
  filters: FilterOptions | undefined,
  options: FilterOptions | undefined,
) => {
  const label = getCurrentFilterLabel(type);
  const currentFilter = filters?.[type];
  const currentFilterOptions = options?.[type];

  if (filters && !currentFilter) {
    throw new Error(`Filter does not exist for type ${type}. Please add a filter mapping.`);
  }

  if (currentFilterOptions && !currentFilterOptions) {
    throw new Error(`Filter Option does not exist for type ${type}. Please add a filter option mapping.`);
  }

  return {
    label,
    currentFilter,
    currentFilterOptions,
  };
};

export const getNewLengthIfFilterHasAllKey = (filters: DropdownOption[] = [], options: DropdownOption[] = []) => {
  let length = filters.length ?? 0;

  // Set length to the entire options length if
  // user has chosen 'all' value
  if (filters[0]?.key === ALL_KEY) {
    length = options.length;
  }

  return length;
};

export const getFilterDisplayValue = (
  type: DropdownFilterOptions,
  filters: FilterOptions | undefined,
  options: FilterOptions | undefined,
): string => {
  if (!options) {
    return 'Loading...';
  }

  const { label, currentFilter, currentFilterOptions } = getCurrentFilterValues(type, filters, options);
  const newLength = getNewLengthIfFilterHasAllKey(currentFilter, currentFilterOptions);

  return numSelectedLabel(label, newLength, currentFilterOptions?.length ?? 0);
};

/*
 * © 2021 Thoughtworks, Inc.
 */

import { AppBar, Toolbar, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import useStyles from './headerBarStyles';
import logo from './ccf_logo.png';

const HeaderBar = (): ReactElement => {
  const classes = useStyles();

  return (
    <AppBar position="sticky" square={true} className={classes.appBar} id="app-bar-header">
      <Toolbar className={classes.navContainer}>
        <NavLink to="/" className={classes.title}>
          <img src={logo} alt={'Digital Carbon Insights Logo'} className={classes.logo} />
          <Typography component="h1" variant="h5">
            Digital Carbon Insights
          </Typography>
        </NavLink>
        <NavLink to="/app/recommendations">
          <Typography component="h2" style={{ visibility: 'hidden' }}>
            RECOMMENDATIONS
          </Typography>
        </NavLink>
      </Toolbar>
    </AppBar>
  );
};

export default HeaderBar;

/*
 * © 2021 Thoughtworks, Inc.
 */

import type { RecommendationResult } from '@sede-x/ccf-common';
import { CloudEstPerDay, Co2eUnit, DropdownOption, UnknownTypes } from '../../types';
import { co2eUnitMultiplier } from './units';

export const convertCloudEstPerDayToNumberTuple = ({ x, y }: CloudEstPerDay) => [new Date(x).getTime(), y];

export const getMaxOfDataSeries = (series: CloudEstPerDay[]): number => {
  if (series.length === 0) return 1;
  const max = Math.max(
    ...series.map((dataPair) => {
      return dataPair.y;
    }),
  );
  return max ? max : 1;
};

export const roundNumberBasedOnSize = (number: number, digits: number): number => {
  return number >= 1 ? Number(number.toFixed(digits)) : Number(number.toExponential(digits - 1));
};

export const calculatePercentChange = (oldAmount: number, newAmount: number): number => {
  const result = ((oldAmount - newAmount) / Math.abs(oldAmount)) * 100;
  return Math.ceil(result);
};

export const formattedNumberWithCommas = (num: number, decimalPlaces = 2): string =>
  num.toLocaleString(undefined, {
    maximumFractionDigits: decimalPlaces,
  });

export const mapFromRecommendationsToDropdownOptions = (data: RecommendationResult[]): DropdownOption[] => {
  const recommendationTypes: DropdownOption[] = data.map(({ recommendationType }) => {
    return {
      key: recommendationType ? recommendationType : `${UnknownTypes.UNKNOWN_RECOMMENDATION_TYPE}`,
      name: recommendationType ? recommendationType : `${UnknownTypes.UNKNOWN_RECOMMENDATION_TYPE}`,
    };
  });

  return recommendationTypes;
};

/**
 * Formats the value so that it has at most 3 fraction digits and substitutes
 * "< 0.001" if the value is greater than zero but otherwise would be formatted
 * as zero.
 *
 * @param rawValue Raw numeric value to format
 */
export function tableFormatNearZero(rawValue: number): string {
  const formattedValue = rawValue.toLocaleString(undefined, {
    maximumFractionDigits: 3,
  });
  return formattedValue === '0' && rawValue > 0 ? '< 0.001' : formattedValue;
}

/**
 * Formats the raw co2e value, converting to the specified unit as required
 *
 * @param unit The target unit
 * @param rawValue Raw numeric co2e value in metric tonnes
 */
export function tableFormatRawCo2e(unit: Co2eUnit, rawValue: number): string {
  const multiplier = co2eUnitMultiplier[unit];
  return tableFormatNearZero(rawValue * multiplier);
}

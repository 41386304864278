/*
 * © 2021 Thoughtworks, Inc.
 */

import { TimeseriesBreakdown } from '@sede-x/ccf-common';
import { barChartCustomColors, Page, PageEntry } from '../../../../types';

const createCustomBarColors = (pageData: Page<PageEntry>, data: TimeseriesBreakdown[], mainTheme: string): string[] => {
  const regionColorsMap: string[] = [];
  pageData.data.forEach((region) => {
    const currentRegion = region.x[0];
    const cloudProvider = region.x[1];
    const parsedCloudProvider = cloudProvider.substring(cloudProvider.indexOf('(') + 1, cloudProvider.lastIndexOf(')'));
    const regionEmissionData = data.find(
      (item) =>
        String(item.region) === currentRegion ||
        (String(item.cloudProvider) === parsedCloudProvider && String(item.region) === 'Unknown'),
    ) as TimeseriesBreakdown;

    if (!regionEmissionData) {
      regionColorsMap.push(mainTheme);
    } else {
      const mtPerKwHour = regionEmissionData.mtPerKwHour;
      let color = barChartCustomColors[0];
      if (mtPerKwHour >= 0.00064) {
        color = barChartCustomColors[4];
      } else if (mtPerKwHour >= 0.00048 && mtPerKwHour < 0.00064) {
        color = barChartCustomColors[3];
      } else if (mtPerKwHour >= 0.00032 && mtPerKwHour < 0.00048) {
        color = barChartCustomColors[2];
      } else if (mtPerKwHour >= 0.00016 && mtPerKwHour < 0.00032) {
        color = barChartCustomColors[1];
      }
      regionColorsMap.push(color);
    }
  });
  return regionColorsMap;
};

const mapToRange = (value: number, in_min: number, in_max: number, out_min: number, out_max: number): number => {
  return ((value - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
};

export { createCustomBarColors, mapToRange };

/*
 * © 2021 Thoughtworks, Inc.
 */

import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import type { DailyForecastEmission, RecommendationResult, ResourceTableColumns, SortDir } from '@sede-x/ccf-common';
import { SortBy } from '@sede-x/ccf-common';

export interface EstimationResult {
  timestamp: Date;
  serviceEstimates: DailyForecastEmission[];
}

// Date format is YYYY-MM-DD
// The aggregated values on the specified date
export type DataPoint = [string, number];

export interface AggregatedTimeSeriesDateValues {
  co2e: DataPoint[];
  kilowattHours: DataPoint[];
  cost: DataPoint[];
}

export type DailyEstimationTimeSeries = {
  [key: string]: CloudEstPerDay[];
};

export type Subscription = {
  subscriptionId: string;
  name: string;
};

export interface ServiceResult<D> {
  data: D;
  loading: boolean;
  error: Error | null;
}

export interface CloudEstPerDay {
  x: string;
  y: number;
}

export interface DropdownOption {
  key: string;
  name: string;
  cloudProvider?: string;
}

export interface AllFilterOptionMap {
  [type: string]: DropdownOption;
}

export interface FilterOptions {
  cloudProviders?: DropdownOption[];
  accounts?: DropdownOption[];
  services?: DropdownOption[];
  resources?: DropdownOption[];
  resourceGroups?: DropdownOption[];
  recommendationTypes?: DropdownOption[];
  regions?: DropdownOption[];
  dateRange?: MaybeFiltersDateRange;
}

export interface MaxFilterSelectionsDialog {
  show: boolean;
  label?: string;
}

export interface Page<T> {
  data: T[];
  page: number;
}

export interface PageEntry {
  x: string[];
  y: number;
}

export type TimeFilterValue = 0.25 | 1 | 3 | 6 | 36;

export type MaybeFiltersDateRange = {
  timeFilterValue: TimeFilterValue;
  // Format YYYY-MM-DD
  startDate: string;
  endDate?: string;
} | null;

export type LineChartDataTypes = 'co2e' | 'kilowattHours' | 'cost';

export type LineChartDataVisibility = {
  [key in LineChartDataTypes]: boolean;
};

export type ChartProps = {
  data: DailyEstimationTimeSeries;
  dataType?: string;
  dataVisibility: LineChartDataVisibility;
  handleDataVisibility: (id: LineChartDataTypes) => void;
};

export type ApexDonutChartProps = ChartProps & {
  data: EstimationResult[];
};

export type SidePanelProps = {
  drawerWidth: number;
  title: string;
  children: ReactNode;
  defaultIsOpen?: boolean;
  openOnChange?: RecommendationRow;
  onClose?: () => void;
};

export type FilterProps = {
  filters?: FilterOptions;
  setFilters: Dispatch<SetStateAction<FilterOptions>>;
  updateSelections?: (selections: DropdownOption[]) => void;
  options?: FilterOptions;
  updateMaxSelectionsCountWarning?: (value: MaxFilterSelectionsDialog) => void;
};

export type FilterLabelMapping = { [type in DropdownFilterOptions]?: string };

export type UnknownTypesMapping = {
  [type in DropdownFilterOptions]?: UnknownTypes;
};

export type FilterResults = EstimationResult[] | RecommendationResult[];

export type DateRange = {
  min: Date | null;
  max: Date | null;
};

export type XAxisIndexRange = {
  min: number;
  max: number;
};

export type Source = {
  href: string;
  title: string;
};

export type ComparisonItem = {
  icon: React.ReactNode;
  total: number;
  textOne: string;
  textTwo: string;
  source: Source;
};

export type RecommendationRow = RecommendationResult & {
  id: number;
  co2eUnit: Co2eUnit;
};

export enum ChartDataTypes {
  REGION = 'region',
  SERVICE = 'service',
  ACCOUNT = 'subscription',
  RESOURCE = 'resource',
  RESOUCE_GROUP = 'resourceGroup',
}

export enum DropdownFilterOptions {
  SERVICES = 'services',
  RESOURCES = 'resources',
  RESOURCE_GROUPS = 'resourceGroups',
  CLOUD_PROVIDERS = 'cloudProviders',
  ACCOUNTS = 'accounts',
  RECOMMENDATION_TYPES = 'recommendationTypes',
  REGIONS = 'regions',
}

export const filterLabels: FilterLabelMapping = {
  [DropdownFilterOptions.ACCOUNTS]: 'Subscriptions',
  [DropdownFilterOptions.SERVICES]: 'Services',
  [DropdownFilterOptions.RESOURCES]: 'Resources',
  [DropdownFilterOptions.RESOURCE_GROUPS]: 'Resource Groups',
  [DropdownFilterOptions.CLOUD_PROVIDERS]: 'Cloud Providers',
  [DropdownFilterOptions.REGIONS]: 'Regions',
  [DropdownFilterOptions.RECOMMENDATION_TYPES]: 'Recommendation Types',
};

export const barChartCustomColors: string[] = ['#73B500', '#00791E', '#D99200', '#DF5200', '#790000'];

export enum UnknownTypes {
  UNKNOWN_REGION = 'Unknown Region',
  UNKNOWN_SERVICE = 'Unknown Service',
  UNKNOWN_ACCOUNT = 'Unknown Account',
  UNKNOWN_RECOMMENDATION_TYPE = 'Unknown Recommendation Type',
}

export const unknownOptionTypes: UnknownTypesMapping = {
  [DropdownFilterOptions.ACCOUNTS]: UnknownTypes.UNKNOWN_ACCOUNT,
  [DropdownFilterOptions.SERVICES]: UnknownTypes.UNKNOWN_SERVICE,
  [DropdownFilterOptions.REGIONS]: UnknownTypes.UNKNOWN_REGION,
};

export enum Co2eUnit {
  Kilograms = 'Kilograms',
  MetricTonnes = 'MetricTonnes',
}

export const FilterSelectionsMaxCount = {
  Subscriptions: 10,
};

export type ResourceTableData = {
  resourceId: string;
  resourceName: string;
  resourceGroupId: string;
  regionName: string;
  serviceName: string;
  subscriptionName: string;
  subscriptionId: string;
  currentEmissions: number;
  previousEmissions: number;
  change: number;
  changePercentage: number;
};

export type HeadCell = {
  id: ResourceTableColumns;
  label: string;
  width: string;
};

export type EnhancedTableProps = {
  selectAllResources: boolean;
  sortDir: SortDir;
  sortBy: string;
  handleSortBy: (value: string) => void;
  handleSortDir: (value: SortDir) => void;
  handleSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export type ResourceEmissionData = {
  resourceId: string;
  resourceGroupId: string;
  resourceName: string;
  previousEmissions: number;
  currentEmissions: number;
  change: number;
  changePercentage: number;
  serviceName: string;
  regionName: string;
  subscription: {
    id: string;
    name: string;
  };
};

export type Resources = {
  page: number;
  limit: number;
  sortBy: SortBy;
  sortDir: SortDir;
  totalCount: number;
  resources: ResourceEmissionData[];
};

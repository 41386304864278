import { Typography } from '@mui/material';

const Header = ({ text }: { text: string }) => {
  return (
    <Typography
      sx={{
        fontSize: '24px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 900,
        color: 'rgba(0, 0, 0, 0.87)',
        padding: '1rem',
      }}
    >
      {text}
    </Typography>
  );
};

export default Header;

import { useAxiosRequest } from './useAxiosRequest';
import { useAuthentication } from './useAuthentication';
import { getServerAxiosInstance } from '../axios';
import { FilterModel, ResourceTableColumns, SortDir } from '@sede-x/ccf-common';
import { resourceNameMapperCallback } from '../../pages/EmissionsMetricsPage/utils';

export const searchLimit = 3;

export const useResourcesSearch = (
  search: string,
  filters: FilterModel,
  page: number,
  limit: number,
  sortBy: ResourceTableColumns,
  sortDir: SortDir,
) => {
  const { authorizeResult } = useAuthentication();
  const accessToken = authorizeResult?.accessToken;

  const { data, loading, error } = useAxiosRequest(
    async (signal) => {
      if (search.length < searchLimit) {
        return;
      }

      const res = await getServerAxiosInstance(accessToken).post(
        `/emissions/resources/search`,
        {
          ...filters,
          resourceNames: filters.resourceNames?.map(resourceNameMapperCallback),
          sortBy,
          sortDir,
          page,
          limit,
          search,
        },
        {
          signal,
        },
      );

      return res;
    },
    [search, filters, page, limit, sortBy, sortDir, accessToken],
  );

  return { data, loading, error };
};

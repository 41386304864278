/*
 * © 2021 Thoughtworks, Inc.
 */

import React, { Dispatch, ReactElement, SetStateAction, useCallback } from 'react';
import { DropdownOption, FilterOptions, FilterProps } from '../../../types';
import FilterBar from '../../../common/FilterBar';
import ResourceGroupsFilter from './Filters/ResourceGroupsFilter/ResourceGroupsFilter';
import CloudProviderFilter from './Filters/CloudProviderFilter/CloudProviderFilter';
import AccountFilter from './Filters/AccountFilter/AccountFilter';
import ServiceFilter from './Filters/ServiceFilter/ServiceFilter';
import RegionFilter from './Filters/RegionFilter/RegionFilter';
import MonthFilter from './Filters/MonthFilter/MonthFilter';
import { FilterModelResponse } from '@sede-x/ccf-common';
import {
  getDefaultFilterDateRange,
  mapAccountIdsToDropdownOptions,
  mapFilterResponseToDropdownOptions,
} from '../utils';
import { ALL_KEY } from '../../../common/FilterBar/utils/DropdownConstants';
import { getServerAxiosInstance } from '../../../utils/axios';
import { useAuthentication } from '../../../utils/hooks/useAuthentication';
import { debounce } from 'lodash';

type Props = FilterProps & {
  updateMaxSelectionsCountWarning: NonNullable<FilterProps['updateMaxSelectionsCountWarning']>;
  setFilterOptions: Dispatch<SetStateAction<FilterOptions | undefined>>;
  setFiltersLoading: Dispatch<SetStateAction<boolean>>;
};

const cloudProviders = [
  {
    key: 'azure',
    name: 'Azure',
  },
];

const EmissionsFilterBar = ({
  filters,
  setFilters,
  options,
  setFilterOptions,
  setFiltersLoading,
  updateMaxSelectionsCountWarning,
}: Props): ReactElement => {
  const { authorizeResult } = useAuthentication();
  const accessToken = authorizeResult?.accessToken;

  // debounced because we don't want lots of network requests being
  // sent if the user is quickly changing selections.
  const debouncedUpdateSelections = useCallback(
    debounce(async (selections: DropdownOption[]) => {
      const { data } = await getServerAxiosInstance(accessToken).post<FilterModelResponse>(
        `/emissions/account/filters`,
        {
          subscriptionIds: selections.map((x) => x.key),
        },
      );

      const allValue = {
        key: ALL_KEY,
        name: 'all',
      };

      setFilters((prev) => ({
        cloudProviders,
        dateRange: prev.dateRange,
        accounts: mapAccountIdsToDropdownOptions(data.accounts),
        resourceGroups: [allValue],
        resources: [allValue],
        regions: [allValue],
        services: [allValue],
      }));
      setFilterOptions((prev) => ({
        ...mapFilterResponseToDropdownOptions(data),
        dateRange: prev.dateRange,
        accounts: prev.accounts,
      }));
      setFiltersLoading(false);
    }, 1000),
    [accessToken, setFilters, setFiltersLoading, setFilterOptions],
  );

  const filterProps = {
    filters,
    setFilters,
    updateMaxSelectionsCountWarning,
    options,
  };

  const filterComponents = [
    <CloudProviderFilter {...filterProps} />,
    <AccountFilter
      {...filterProps}
      updateSelections={async (selections) => {
        setFilters({
          ...filters,
          accounts: selections,
        });
        setFiltersLoading(true);

        debouncedUpdateSelections(selections);
      }}
    />,
    <ServiceFilter {...filterProps} />,
    <RegionFilter {...filterProps} />,
    <ResourceGroupsFilter {...filterProps} />,
    <MonthFilter {...filterProps} />,
  ];

  const clearAllOnClick = () => {
    setFilters({
      cloudProviders,
      dateRange: getDefaultFilterDateRange(),
      accounts: [],
      resourceGroups: [],
      resources: [],
      regions: [],
      services: [],
    });
  };

  return <FilterBar components={filterComponents} clearAllOnClick={clearAllOnClick} />;
};

export default EmissionsFilterBar;

/*
 * © 2021 Thoughtworks, Inc.
 */

import React, { FunctionComponent, useMemo } from 'react';
import { useTheme } from '@material-ui/core/styles';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Boost from 'highcharts/modules/boost';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';

import { ChartProps } from '../../../../types';
import { getChartColors, CCFTheme } from '../../../../utils/themes';
import { convertCloudEstPerDayToNumberTuple } from '../../../../utils/helpers/transformData';
import { currency } from '../../../../utils/helpers/units';

Boost(Highcharts);
Exporting(Highcharts);
ExportData(Highcharts);

const timeSeriesKeys = {
  co2e: 'co2e',
  kilowattHours: 'kilowattHours',
  cost: 'cost',
};

const formatValue = (value: number) => {
  if (value >= 1e9) {
    return Highcharts.numberFormat(value / 1e9, 2) + 'b';
  } else if (value >= 1e6) {
    return Highcharts.numberFormat(value / 1e6, 2) + 'm';
  } else if (value >= 1e3) {
    return Highcharts.numberFormat(value / 1e3, 2) + 'k';
  } else if (value < 1) {
    return Highcharts.numberFormat(value, 2);
  } else {
    return Highcharts.numberFormat(value, 2);
  }
};

const LineChart: FunctionComponent<ChartProps> = ({ data, dataVisibility, handleDataVisibility }) => {
  const theme = useTheme() as CCFTheme;

  const options = useMemo<Highcharts.Options>(() => {
    const colors = getChartColors(theme);
    const [blue, yellow, green] = [colors[0], colors[5], colors[8]];
    const co2SeriesData = data[timeSeriesKeys.co2e].map(convertCloudEstPerDayToNumberTuple);
    const kilowattHoursSeriesData = data[timeSeriesKeys.kilowattHours].map(convertCloudEstPerDayToNumberTuple);
    const costSeriesData = data[timeSeriesKeys.cost].map(convertCloudEstPerDayToNumberTuple);

    const options: Highcharts.Options = {
      chart: {
        type: 'line',
        backgroundColor: theme.palette.background.paper,
        shadow: false,
      },
      title: {
        text: 'Cloud Usage',
        style: {
          fontSize: '24px',
        },
      },
      xAxis: {
        type: 'datetime',
      },
      yAxis: [
        {
          title: {
            text: 'tCO2eq (metric tons)',
            style: {
              color: blue,
            },
          },
          tickAmount: 10,
          visible: dataVisibility.co2e,
        },
        {
          title: {
            text: 'Kilowatt Hours (kWh)',
            style: {
              color: yellow,
            },
          },
          tickAmount: 10,
          visible: dataVisibility.kilowattHours,
          opposite: true,
        },
        {
          title: {
            text: currency.symbol,
            style: {
              color: green,
            },
          },
          tickAmount: 10,
          visible: dataVisibility.cost,
          opposite: true,
        },
      ],
      plotOptions: {
        series: {
          marker: {
            enabled: true,
            radius: 5,
          },
        },
      },
      series: [
        {
          type: 'line',
          name: 'Emissions',
          data: co2SeriesData,
          visible: dataVisibility['co2e'],
          yAxis: 0,
          tooltip: {
            pointFormatter: function () {
              const value = this.y ?? 0;
              const formattedValue = formatValue(value);
              return `Emissions: <b>${formattedValue}</b> tCO2e`;
            },
          },
          events: {
            legendItemClick: () => handleDataVisibility('co2e'),
          },
        },
        {
          type: 'line',
          name: 'Energy',
          data: kilowattHoursSeriesData,
          visible: dataVisibility['kilowattHours'],
          yAxis: 1,
          tooltip: {
            pointFormatter: function () {
              const value = this.y ?? 0;
              const formattedValue = formatValue(value);
              return `Kilowatt Hours: <b>${formattedValue}</b> kilowatt hrs`;
            },
          },
          events: {
            legendItemClick: () => handleDataVisibility('kilowattHours'),
          },
        },
        {
          type: 'line',
          name: 'Cost',
          data: costSeriesData,
          visible: dataVisibility['cost'],
          yAxis: 2,
          tooltip: {
            pointFormatter: function () {
              const value = this.y ?? 0;
              const formattedValue = formatValue(value);
              return `Cost: <b>${currency.symbol}${formattedValue}</b>`;
            },
          },
          events: {
            legendItemClick: () => handleDataVisibility('cost'),
          },
        },
      ],
      tooltip: {
        valueDecimals: 2,
        useHTML: true,
        animation: true,
        shadow: true,
        split: true,
      },
      colors: [blue, yellow, green],
      credits: {
        enabled: false,
      },
      boost: {
        enabled: true,
        useGPUTranslations: true,
        usePreallocated: true,
      },
      exporting: {
        fallbackToExportServer: false,
        buttons: {
          contextButton: {
            text: 'Export',
          },
        },
      },
    };

    return options;
  }, [theme, data]);

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default LineChart;

/*
 * © 2021 Thoughtworks, Inc.
 */

import React, { FunctionComponent } from 'react';
import { DropdownFilterOptions, DropdownOption, FilterProps } from '../../../../../types';
import FilterDropdown from '../../../../../common/FilterDropdown/FilterDropdown';
import { getCurrentFilterLabel, getFilterDisplayValue } from '../../../../../common/FilterBar/utils/FiltersUtil';

const RegionFilter: FunctionComponent<FilterProps> = ({ filters, setFilters, options }) => {
  return (
    <FilterDropdown
      id="regions-filter"
      allLabel={getCurrentFilterLabel(DropdownFilterOptions.REGIONS)}
      displayValue={getFilterDisplayValue(DropdownFilterOptions.REGIONS, filters, options)}
      options={options?.regions}
      selections={filters?.regions}
      updateSelections={(selections: DropdownOption[]) => {
        setFilters({
          ...filters,
          regions: selections,
        });
      }}
    />
  );
};

export default RegionFilter;

/*
 * © 2021 Thoughtworks, Inc.
 */

import React, { ReactElement, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import SelectDropdown from '../../../common/SelectDropdown';
import DashboardCard from '../../../layout/DashboardCard';
import AWSMap from './AWSMap.png';
import GCPMap from './GCPMap.png';
import AzureMap from './AzureMap.png';
import useStyles from './carbonIntensityStyles';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';

type CloudProvider = 'AWS' | 'GCP' | 'Azure';

type IntensityMaps = {
  [provider in CloudProvider]: string;
};

const CarbonIntensityMap = (): ReactElement => {
  const [cloudProvider, setCloudProvider] = useState('Azure');
  const classes = useStyles();

  const intensityMaps: IntensityMaps = {
    AWS: AWSMap,
    GCP: GCPMap,
    Azure: AzureMap,
  };

  const handleChange: SelectInputProps['onChange'] = (event) => {
    setCloudProvider(event.target.value as string);
  };

  const dropdownOptions = Object.keys(intensityMaps).map((key) => {
    return {
      key,
      value: key,
    };
  });

  return (
    <DashboardCard>
      <>
        <Box className={classes.topContainer}>
          <Typography className={classes.title}>Carbon Intensity Map</Typography>
          <SelectDropdown
            id="map"
            value={cloudProvider}
            dropdownOptions={dropdownOptions}
            handleChange={handleChange}
          />
        </Box>
        <Box paddingX={3} textAlign="center">
          <img
            src={intensityMaps[cloudProvider as keyof typeof intensityMaps]}
            className={classes.map}
            alt={`${cloudProvider} Map`}
            data-testid={`${cloudProvider.toLowerCase()}IntensityMap`}
          />
        </Box>
      </>
    </DashboardCard>
  );
};

export default CarbonIntensityMap;

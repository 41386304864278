import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledLoadingContainer } from './AuthCallback.styles';

const AuthCallback = ({
  isLoading,
  hasLoginError,
  isLoggedIn,
}: {
  isLoading: boolean;
  hasLoginError: boolean;
  isLoggedIn: boolean;
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn && !isLoading) {
      navigate('/app/emissions');
    }

    if (hasLoginError && !isLoading) {
      navigate('/error');
    }
  }, [isLoggedIn, isLoading]);

  return (
    <StyledLoadingContainer>
      <div style={{ display: 'flex' }}>Loading...</div>
    </StyledLoadingContainer>
  );
};

export default AuthCallback;

/*
 * © 2021 Thoughtworks, Inc.
 */

import React, { Dispatch, ReactElement, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { DateRange, FilterOptions, FilterProps } from 'src/types';
import EmissionsFilterBar from './EmissionsFilterBar';
import CarbonIntensityMap from './CarbonIntensityMap';
import EmissionsOverTimeCard from './EmissionsOverTimeCard';
import useStyles from './emissionsMetricsStyles';
import EmissionsSidePanel from './EmissionsSidePanel/EmissionsSidePanel';
import ResourceTable from './ResourceTable/ResourceTable';
import { FilterModel } from '@sede-x/ccf-common';
import { CarbonComparisonCard, EmissionsBreakdownCard, RecommendationsTable } from '../../lib';
import { mapFilterDropdownsToFilterModel, mapFilterResponseToDropdownOptions } from './utils';
import { useAccountFilter } from '../../utils/hooks/useAccountFilter';
import { useDropdownFilters } from '../../utils/hooks/useDropdownFilters';
import { useAggregatedDailyEmissionsTimeSeries } from '../../utils/hooks/useAggregatedDailyEmissionsTimeSeries';
import LoadingMessage from '../../common/LoadingMessage/LoadingMessage';

type EmissionsMetricsPageProps = FilterProps & {
  updateMaxSelectionsCountWarning: NonNullable<FilterProps['updateMaxSelectionsCountWarning']>;
  filterOptions: FilterOptions | undefined;
  setFilterOptions: Dispatch<SetStateAction<FilterOptions | undefined>>;
  setFiltersLoading: Dispatch<SetStateAction<boolean>>;
  filtersLoading: boolean;
};

export default function EmissionsMetricsPage({
  filters,
  setFilters,
  filterOptions,
  setFilterOptions,
  filtersLoading,
  setFiltersLoading,
  updateMaxSelectionsCountWarning,
}: EmissionsMetricsPageProps): ReactElement<EmissionsMetricsPageProps> {
  const [_, __] = useState<DateRange>({
    min: null,
    max: null,
  });
  const classes = useStyles();
  const accountFilter = useAccountFilter();

  const subscriptionIds = useMemo(() => filters?.accounts?.map((x) => x.key) ?? [], [filters?.accounts]);

  const dropdownFilterOptions = useDropdownFilters(subscriptionIds);

  const filterModel = useMemo<FilterModel>(() => {
    const filterModel = mapFilterDropdownsToFilterModel({
      ...filters,
      dateRange: filters?.dateRange,
    });

    return filterModel;
  }, [filters]);

  const { data, loading: dailyEmissionsLoading } = useAggregatedDailyEmissionsTimeSeries(filterModel);

  useEffect(() => {
    if (!dropdownFilterOptions.data || !accountFilter.data) {
      return;
    }

    const mappedDropdownFilters = mapFilterResponseToDropdownOptions({
      ...dropdownFilterOptions.data,
      accounts: accountFilter.data,
    });

    setFilterOptions(mappedDropdownFilters);
  }, [dropdownFilterOptions.data, accountFilter.data]);

  return (
    <div className={classes.pageContainer}>
      <EmissionsSidePanel />
      <EmissionsFilterBar
        filters={filters}
        setFilters={setFilters}
        options={filterOptions}
        setFilterOptions={setFilterOptions}
        setFiltersLoading={setFiltersLoading}
        updateMaxSelectionsCountWarning={updateMaxSelectionsCountWarning}
      />
      <div className={classes.boxContainer}>
        <Grid container spacing={3}>
          {filtersLoading ? (
            <LoadingMessage message="Loading..." />
          ) : (
            <>
              <EmissionsOverTimeCard dailyEmissionsLoading={dailyEmissionsLoading} data={data} />
              <Grid item xs={12}>
                <ResourceTable setFilters={setFilters} filters={filterModel} />
                <Grid container spacing={3} className={classes.gridCardRow}>
                  <CarbonComparisonCard data={data} />
                  <EmissionsBreakdownCard filters={filterModel} accounts={filters?.accounts} />
                </Grid>
              </Grid>
              <RecommendationsTable data={data} filters={filterModel} />
            </>
          )}
          <CarbonIntensityMap />
        </Grid>
      </div>
    </div>
  );
}

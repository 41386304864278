import { Account, AllFilter, FilterModel, FilterModelResponse } from '@sede-x/ccf-common';
import { DropdownOption, FilterOptions, TimeFilterValue } from '../../types';
import { ALL_KEY, buildAndOrderDropdownOptions } from '../../common/FilterBar/utils/DropdownConstants';
import moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-DD';

export const mapAccountIdsToDropdownOptions = (
  accounts: Account[] | AllFilter | null | undefined,
): DropdownOption[] => {
  if (!accounts) {
    return [];
  }

  const accountOptions: DropdownOption[] = buildAndOrderDropdownOptions(
    accounts.map((account) => {
      if (account === ALL_KEY) {
        return {
          key: ALL_KEY,
          name: 'all',
        };
      }

      return {
        key: account.id,
        name: account.name,
      };
    }),
  );

  return accountOptions;
};

export const getDefaultFilterDateRange = (): { startDate: string; timeFilterValue: TimeFilterValue } => ({
  startDate: moment(0).format(DATE_FORMAT),
  timeFilterValue: 36,
});

export const mapFilterResponseToDropdownOptions = (data: FilterModelResponse | null): FilterOptions => {
  const { accounts, serviceNames, resourceGroupIds, resourceNames, regionNames } = data ?? {};

  const accountOptions = mapAccountIdsToDropdownOptions(accounts);
  const cloudProviders: DropdownOption[] = buildAndOrderDropdownOptions([
    {
      key: 'azure',
      name: 'Azure',
    },
  ]);
  const services: DropdownOption[] = buildAndOrderDropdownOptions(
    serviceNames?.map((key) => {
      return {
        key: key,
        name: key,
      };
    }),
  );
  const resourceGroups: DropdownOption[] = buildAndOrderDropdownOptions(
    resourceGroupIds?.map((key) => {
      return {
        key: key,
        name: key,
      };
    }),
  );
  const resources: DropdownOption[] = buildAndOrderDropdownOptions(
    resourceNames?.map((key) => {
      return {
        key: key,
        name: key,
      };
    }),
  );
  const regions: DropdownOption[] = buildAndOrderDropdownOptions(
    regionNames?.map((key) => {
      return {
        key: key,
        name: key,
      };
    }),
  );

  return {
    cloudProviders,
    accounts: accountOptions,
    services,
    resourceGroups,
    resources,
    regions,
    dateRange: getDefaultFilterDateRange(),
  };
};

export const mapFilterDropdownsToFilterModel = (data: FilterOptions): FilterModel => {
  const { accounts = [], services = [], resourceGroups = [], resources = [], regions = [], dateRange } = data ?? {};

  const subscriptionIds: string[] = accounts.map(({ key }) => {
    return key;
  });
  const serviceNames: string[] = services.map(({ key }) => {
    return key;
  });
  const resourceGroupIds: string[] = resourceGroups.map(({ key }) => {
    return key;
  });
  // Use the name as the key is a specific resource for a specific date/time instead/
  // whereas we want the aggregate
  const resourceNames = resources.map(({ key, name }) => {
    return {
      key,
      value: name,
    };
  });
  const regionNames: string[] = regions.map(({ key }) => {
    return key;
  });

  return {
    subscriptionIds,
    serviceNames,
    resourceGroupIds,
    resourceNames,
    regionNames,
    dateFrom: dateRange?.startDate,
    dateTo: dateRange?.endDate,
  };
};

export const resourceNameMapperCallback = (filter: string | { key: string; value: string }) => {
  if (typeof filter === 'string') {
    return filter;
  }

  return filter.value;
};

/*
 * © 2021 Thoughtworks, Inc.
 */

import React, { FunctionComponent, useState } from 'react';
import NoDataMessage from '../../../common/NoDataMessage';
import DashboardCard from '../../../layout/DashboardCard';
import { AggregatedTimeSeriesDateValues, DailyEstimationTimeSeries, LineChartDataTypes } from '../../../types';
import LineChart from './LineChart/LineChart';
import LoadingMessage from '../../../common/LoadingMessage/LoadingMessage';

type EmissionsOverTimeProps = {
  data: AggregatedTimeSeriesDateValues | null;
  dailyEmissionsLoading: boolean;
};

const EmissionsOverTimeCard: FunctionComponent<EmissionsOverTimeProps> = ({ data, dailyEmissionsLoading }) => {
  const [dataVisibility, setDataVisibility] = useState({
    co2e: true,
    kilowattHours: false,
    cost: false,
  });

  const convertAggregatedTimeSeriesDataValues = (
    timeSeriesResult: AggregatedTimeSeriesDateValues,
  ): DailyEstimationTimeSeries => {
    const convertedData: DailyEstimationTimeSeries = {};

    for (const key in timeSeriesResult) {
      convertedData[key] = timeSeriesResult[key as keyof AggregatedTimeSeriesDateValues].map(([x, y]) => ({ x, y }));
    }

    return convertedData;
  };

  const handleDataVisibility = (id: LineChartDataTypes) => {
    setDataVisibility((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  if (dailyEmissionsLoading) {
    return <LoadingMessage message="Loading..." />;
  }

  const hasData = data && (data?.co2e.length > 0 || data?.cost.length > 0 || data?.kilowattHours.length > 0);

  return hasData ? (
    <DashboardCard testId="cloudUsage">
      <LineChart
        data={convertAggregatedTimeSeriesDataValues(data)}
        dataVisibility={dataVisibility}
        handleDataVisibility={handleDataVisibility}
      />
    </DashboardCard>
  ) : (
    <NoDataMessage isTop isBold title="Cloud Usage" />
  );
};

export default EmissionsOverTimeCard;

/*
 * © 2021 Thoughtworks, Inc.
 */

import { DropdownOption } from '../../../types';

function sortByCloudProvider(
  { cloudProvider: cloudProvider1 = '' }: DropdownOption,
  { cloudProvider: cloudProvider2 = '' }: DropdownOption,
) {
  return cloudProvider1.localeCompare(cloudProvider2);
}

export function alphabetizeDropdownOptions(dropdownOptions: DropdownOption[]): DropdownOption[] {
  dropdownOptions.sort((a, b) => {
    const normalizedA = a.name.trim().toLowerCase();
    const normalizedB = b.name.trim().toLowerCase();
    return normalizedA.localeCompare(normalizedB);
  });
  return dropdownOptions;
}

export const ALL_KEY = 'all';

export const buildAndOrderDropdownOptions = (dropdownOptions: DropdownOption[] | undefined = []): DropdownOption[] => {
  return alphabetizeDropdownOptions(dropdownOptions).sort(sortByCloudProvider);
};

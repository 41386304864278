/*
 * © 2021 Thoughtworks, Inc.
 */

import React, { FunctionComponent, ReactElement } from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import BootstrapInput from './selectDropdownStyles';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';

type SelectDropdownProps = {
  id?: string;
  value: string;
  dropdownOptions: { key: string; value: string }[];
  handleChange: SelectInputProps['onChange'];
};

const SelectDropdown: FunctionComponent<SelectDropdownProps> = ({
  id,
  value,
  dropdownOptions,
  handleChange,
}): ReactElement => (
  <FormControl variant="outlined">
    <Select data-testid="select" id={`${id}-selector`} value={value} onChange={handleChange} input={<BootstrapInput />}>
      {dropdownOptions.map(({ key, value }) => (
        <MenuItem key={key} id={`${key}-dropdown`} value={key}>
          {/* Makes sure first letter of option is capitalized for display */}
          {value.charAt(0).toUpperCase() + value.slice(1)}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default SelectDropdown;

/*
 * © 2021 Thoughtworks, Inc.
 */

import React, { FunctionComponent } from 'react';
import { DropdownFilterOptions, DropdownOption, FilterProps } from '../../../../../types';
import { getCurrentFilterLabel, getFilterDisplayValue } from '../../../../../common/FilterBar/utils/FiltersUtil';
import FilterDropdown from '../../../../../common/FilterDropdown/FilterDropdown';

const ResourceGroupsFilter: FunctionComponent<FilterProps> = ({ filters, setFilters, options }) => {
  return (
    <FilterDropdown
      id="resource-groups-filter"
      popperWidth={400}
      allLabel={getCurrentFilterLabel(DropdownFilterOptions.RESOURCE_GROUPS)}
      displayValue={getFilterDisplayValue(DropdownFilterOptions.RESOURCE_GROUPS, filters, options)}
      options={options?.resourceGroups}
      selections={filters?.resourceGroups}
      updateSelections={(selections: DropdownOption[]) => {
        setFilters({
          ...filters,
          resourceGroups: selections,
        });
      }}
    />
  );
};

export default ResourceGroupsFilter;

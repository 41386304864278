/*
 * © 2021 Thoughtworks, Inc.
 */

import React, { FunctionComponent } from 'react';
import { DropdownFilterOptions, DropdownOption, FilterProps } from '../../../../../types';
import FilterDropdown from '../../../../../common/FilterDropdown/FilterDropdown';
import { getCurrentFilterLabel, getFilterDisplayValue } from '../../../../../common/FilterBar/utils/FiltersUtil';

const CloudProviderFilter: FunctionComponent<FilterProps> = ({ filters, options, setFilters }) => {
  return (
    <FilterDropdown
      id="cloud-provider-filter"
      allLabel={getCurrentFilterLabel(DropdownFilterOptions.CLOUD_PROVIDERS)}
      displayValue={getFilterDisplayValue(DropdownFilterOptions.CLOUD_PROVIDERS, filters, options)}
      options={options?.cloudProviders}
      selections={filters?.cloudProviders}
      updateSelections={(selections: DropdownOption[]) => {
        setFilters({
          ...filters,
          cloudProviders: selections,
        });
      }}
    />
  );
};

export default CloudProviderFilter;

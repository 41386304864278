import { ServiceResult } from '../../types';
import { FilterModelResponse } from '@sede-x/ccf-common';
import { useAxiosRequest } from './useAxiosRequest';
import { getServerAxiosInstance } from '../axios';
import { useAuthentication } from './useAuthentication';

export const useDropdownFilters = (subscriptionIds: string[]): ServiceResult<FilterModelResponse | null> => {
  const { authorizeResult } = useAuthentication();
  const accessToken = authorizeResult?.accessToken;

  const { data, loading, error } = useAxiosRequest<FilterModelResponse>(
    async (signal) => {
      const res = await getServerAxiosInstance(accessToken).post<FilterModelResponse>(
        `/emissions/account/filters`,
        {
          subscriptionIds,
        },
        {
          signal,
        },
      );

      return res;
    },
    [subscriptionIds, accessToken],
  );

  return { data, loading, error };
};

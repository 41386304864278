import { BreakdownType, FilterModel, TimeseriesBreakdown } from '@sede-x/ccf-common';

import { ServiceResult } from '../../types';
import { useAxiosRequest } from './useAxiosRequest';
import { getServerAxiosInstance } from '../axios';
import { useAuthentication } from './useAuthentication';
import { resourceNameMapperCallback } from '../../pages/EmissionsMetricsPage/utils';

export const useEmissionsBreakdownTotalCo2e = (
  filters: FilterModel,
  type: BreakdownType,
): ServiceResult<TimeseriesBreakdown | null> => {
  const { authorizeResult } = useAuthentication();
  const accessToken = authorizeResult?.accessToken;

  const { data, loading, error } = useAxiosRequest<TimeseriesBreakdown>(
    async (signal) => {
      const res = await getServerAxiosInstance(accessToken).post<TimeseriesBreakdown>(
        `/emissions/breakdown/${type}`,
        {
          ...filters,
          resourceNames: filters.resourceNames?.map(resourceNameMapperCallback),
        },
        {
          signal,
        },
      );

      return res;
    },
    [filters, type, accessToken],
  ); //The cloud providers fetched here are all AWS or GCP - not Azure
  return { data, loading, error };
};

export const useEmissionsBreakdownMtPerKwHour = (): ServiceResult<TimeseriesBreakdown[] | null> => {
  const { authorizeResult } = useAuthentication();
  const accessToken = authorizeResult?.accessToken;

  const { data, loading, error } = useAxiosRequest<TimeseriesBreakdown[]>(async () => {
    const res = await getServerAxiosInstance(accessToken).get<TimeseriesBreakdown[]>(`regions/emissions-factors`);

    return res;
  }, [accessToken]);
  return { data, loading, error };
};
